export const PERIOD_MAP = {
  DIURNAL: 'diurno',
  NOTURNAL: 'noturno',
};

export const PERIOD_HOUR_MAP = {
  DIURNAL: '06h ás 20h',
  NOTURNAL: '20h ás 06h',
};
  
export const TRANSFER_MAP = {
  PIX: 'pix',
  BILL_PAYMENT: 'boleto',
  INTERNAL_TRANSFER: 'contas Somapay',
};

export const PERSON_MAP = {
  PHYSICAL_PERSON: ' por pessoa física',
  LEGAL_PERSON: ' por pessoa jurídica',
};
  