import React from 'react';
import { Box, Button, Divider, Drawer, Grid, Typography } from '@material-ui/core';
import { ReactComponent as IconClose } from '../../../../assets/iconClose.svg';
import useStyles from './styles';

const DrawerMenu = ({ 
    isOpen, 
    handleClose, 
    title, 
    children 
}) => {
    const classes = useStyles();
    
    return (
        <>
            <Drawer
                anchor='right'
                open={isOpen}
                onClose={() => handleClose(false)}
                PaperProps={{
                    style: {
                        paddingBottom: 0
                    }
                }}
            >
                <Box className={classes.wrapper}>
                    <Box className={classes.header}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item>
                                <Typography variant='h4' className={classes.titleText}>{title}</Typography>
                            </Grid>
                            <Grid item  className='close-button-container'>
                                <Button 
                                    onClick={()=>handleClose(false)}
                                    className={classes.closeButton}
                                >
                                    <IconClose />
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Box>
                    {children}
                </Box>
            </Drawer>
        </>
    );
};

export default DrawerMenu;
