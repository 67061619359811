import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    cardWrapper: {
      border: 'solid !important',
      borderColor: `${theme.palette.primary.main} !important`,
      borderWidth: '1px !important',
    },
  }),
);
