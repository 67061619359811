import React, { ReactNode, CSSProperties } from 'react';
import { Avatar, useTheme } from '@material-ui/core';

interface AvatarIconProps {
  variant?: 'rounded' | 'circle' | 'square';
  style?: CSSProperties;
  children: ReactNode;
}

export const AvatarIcon = ({ variant = 'circle', style = {}, children }: AvatarIconProps) => {
  const theme = useTheme();

  return (
    <Avatar
      style={{
        backgroundColor: theme.palette.primary?.main + '10',
        color: theme.palette.primary?.main,
        borderRadius: variant === 'rounded' ? '0.375rem' : undefined,
        ...style,
      }}
    >
      {children}
    </Avatar>
  );
}