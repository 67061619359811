import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Typography,
} from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';

import { AccordionContext } from '../FormAccordion/context';

import { useStyles } from './styles';

export default function TransactionAccordion({ title, icon, children }) {
  const styles = useStyles();

  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      className={styles.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreRounded />}
        aria-controls={`${title}-content`}
        className={styles.summary}
      >
        {!!icon && (
          <Avatar variant="rounded" className={styles.avatar}>
            {icon}
          </Avatar>
        )}
        <Typography component="h2" variant="h2" className={styles.title}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails id={`${title}-content`} className={styles.details}>
        <AccordionContext.Provider value={{ setExpanded }}>
          {children}
        </AccordionContext.Provider>
      </AccordionDetails>
    </Accordion>
  );
}
