import { reduxApi } from '../../../services/reduxApi';
import { PixStatusActions as Actions } from './actions';

const pixKeysTags = {
    tag: 'PIX_KEYS',
    listAbstractTag: { type: 'PIX_KEYS', id: 'LIST' },
};

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [pixKeysTags.tag],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getKeysList: builder.query({
        query: ({ companyCnpj }) => `/pix/key/all/${companyCnpj}`,
        providesTags: [pixKeysTags.listAbstractTag],
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
            try {
                dispatch(Actions.clearError());
                dispatch(Actions.setLoading(true));
                await queryFulfilled;
                dispatch(Actions.setLoading(false));
            } catch ({ error }) {
                dispatch(Actions.setLoading(false));
                dispatch(Actions.setError(error || 'Erro desconhecido'));
            }
        },
      }),

      getKeyValidation: builder.mutation({
        query: ({ key, type, companyCnpj }) => ({
          url: `/pix/key/validate`,
          method: 'POST',
          body: { key, type, cnpj: companyCnpj },
        }),

        transformErrorResponse: (response) => {
          return response?.data?.errors?.[0]?.errorDetail;
        },

        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
            try {
                dispatch(Actions.clearError());
                dispatch(Actions.setLoading(true));
                await queryFulfilled;
                dispatch(Actions.setLoading(false));
            } catch ({ error }) {
                dispatch(Actions.setLoading(false));
                dispatch(Actions.setError(error || 'Erro desconhecido'));
            }
        },
      }),

      createNewPixKey: builder.mutation({
        query: ({ key, type, companyCnpj, pin, messageId }) => ({
          url: '/pix/key/create',
          method: 'POST',
          body: {
            type,
            messageId,
            key,
            ownerTaxIdNumber: companyCnpj,
            confirmationCode: pin ?? null,
          },
        }),

        transformErrorResponse: (response) => {
          return response?.data?.errors?.[0]?.errorDetail;
        },

        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
            try {
                dispatch(Actions.clearError());
                dispatch(Actions.setLoading(true));
                await queryFulfilled;
                dispatch(Actions.setLoading(false));
            } catch ({ error }) {
                dispatch(Actions.setLoading(false));
                dispatch(Actions.setError(error || 'Erro desconhecido'));
            }
        },
        invalidatesTags: [pixKeysTags.listAbstractTag],
      }),
      deletePixKey: builder.mutation({
        query: ({ key, type, companyCnpj }) => ({
          url: `/pix/key`,
          method: 'DELETE',
          body: {
            key,
            type,
            ownerTaxIdNumber: companyCnpj,
            reason: 'USER_REQUESTED',
          },
        }),

        transformErrorResponse: (response) => {
          return response?.data?.errors?.[0]?.errorDetail;
        },

        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          try {
            dispatch(Actions.clearError());
            dispatch(Actions.setLoading(true));
            await queryFulfilled;
            dispatch(Actions.setLoading(false));
          } catch ({ error }) {
            dispatch(Actions.setLoading(false));
            dispatch(Actions.setError(error || 'Erro desconhecido'));
          }
        },
        invalidatesTags: [pixKeysTags.listAbstractTag],
      }),
    }),
  });

export const {
  useGetKeysListQuery,
  useGetKeyValidationMutation,
  useCreateNewPixKeyMutation,
  useDeletePixKeyMutation
} = slice;
