import { createSlice } from '@reduxjs/toolkit';

const pixStatusSlice = createSlice({
  name: 'pixStatus',
  initialState: {
    validating: false,
    isLoading: false,
    error: null,
  },
  reducers: {
    setValidating(state, action) {
      state.validating = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
  },
});

export const PixStatusActions = pixStatusSlice.actions;
export const pixStatusReducer = pixStatusSlice.reducer;
export default pixStatusReducer;