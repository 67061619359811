import React from 'react';
import { FilterList } from '@material-ui/icons';
import { Box, Drawer } from '@material-ui/core';
import Buttons from '../../../Buttons';
import { FilterHeader } from './Components/FilterHeader';
import { FilterFields } from './Components/FilterFields';
import { FilterBottom } from './Components/FilterBottom';
import { useMobileMode } from './Hooks/useMobileMode';
import Form from '../../../Form';

export function MobileMode({
  children,
  defaultValues = {},
  validation = null,
  handleRequests = () => {},
}) {
  const { filterComponentObject, form } = useMobileMode({
    fields: children,
    defaultValues,
    validation,
  });

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'var(--white)',
          width: '100%',
          display: 'flex',
          paddingY: '1rem',
          position: 'sticky',
          top: 0,
        }}
      >
        <Buttons.PrimaryButton
          icon={<FilterList />}
          size="large"
          color="secondary"
          title="Filtros"
          onClick={filterComponentObject.handleOpenFilterDrawer}
          titleStyle={{ fontWeight: 'bold' }}
          style={{ width: '100%' }}
        />
      </Box>

      <Form.FormBase
        id="filter-form"
        onSubmit={form.handleSubmit(handleRequests)}
      >
        <Drawer
          open={filterComponentObject.isMobileFilterOpen}
          anchor="bottom"
          PaperProps={{
            style: { height: '100vh', padding: 0 },
          }}
          SlideProps={{ mountOnEnter: true, unmountOnExit: true }}
        >
          <FilterHeader
            onCloseFilterDrawer={filterComponentObject.handleOpenFilterDrawer}
          />

          <FilterFields control={form.control}>
            {filterComponentObject.fieldsList}
          </FilterFields>

          <FilterBottom
            isClearFieldsDisabled={filterComponentObject.isClearFieldsDisabled}
            onClearFields={() => {
              filterComponentObject.handleClearFields();

              document
                .getElementById('filter-form')
                .dispatchEvent(new Event('submit', { cancelable: true }));

              filterComponentObject.handleOpenFilterDrawer();
            }}
            onSearchButtonClicked={() => {
              document
                .getElementById('filter-form')
                .dispatchEvent(new Event('submit', { cancelable: true }));
              filterComponentObject.handleOpenFilterDrawer();
            }}
          />
        </Drawer>
      </Form.FormBase>
    </>
  );
}
