import React from 'react';
import {
  Paper,
  MuiThemeProvider,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  TablePagination,
  TableFooter,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import FakeRowTable from './FakeRowTable';

import { ReactComponent as QrcodeIcon } from '../../../../assets/qrcodeIcon.svg';
import { ReactComponent as ResetIcon } from '../../../../assets/resetIcon.svg';

import colorStatus from './ColorStatus';
import styles from './styles';
import convertBrazilianDate from '../../../../utils/convertBrazilianDate';
import { api } from '../../../../services/api';

import { useStyles, theme } from '../styles';


import { isExpiredDate } from '../utils/dates'

import QrCodeModal from '../../../../components/QrCodeModal/index';

export default ({
  data,
  totalSize,
  pageSize,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  TablePaginationActions,
  loading,
  isLoadingQrCode,
  cashInSelected,
  handleClickQrCode
}) => {
  const classes = useStyles();

  const getStatus = (isExpired, row) => {
    if (isLoadingQrCode && cashInSelected?.id == row?.id) return 'isLoading';
    if (isExpired) return 'expired';
    return 'valid';
  };

  const renderIconButton = (row) => {
    const renderQrCodeOptions = ['Aguardando confirmação', 'Expirado', 'Aguardando pagamento']
    const shouldRenderQrCodeButton = renderQrCodeOptions.includes(row?.status)

    if(row?.type === 'PIX' && shouldRenderQrCodeButton) {

      const isExpired = row?.qrcodeExpired
    
      const status = getStatus(isExpired, row)

      const iconMap = {
        'expired': <Tooltip title='Regerar QR Code Pix'><ResetIcon/></Tooltip>,
        'valid': <Tooltip title='Visualizar QR Code Pix'><QrcodeIcon /></Tooltip>,
        'isLoading': <CircularProgress size={20} className={classes.circularProgress}/>
      }

      return (
        <IconButton 
          onClick={() => handleClickQrCode(row)}
          aria-label="qr-code-pix"
        >
          {iconMap[status]}
        </IconButton>
      );
    }
  };


  return (
    <MuiThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableHeadLabel}>Situação</TableCell>
              <TableCell style={styles.tableHeadLabel}>Tipo</TableCell>
              <TableCell style={styles.tableHeadLabel}>Empresa</TableCell>
              <TableCell style={styles.tableHeadLabel}>Solicitação</TableCell>
              <TableCell style={styles.tableHeadLabel}>Depósito</TableCell>
              <TableCell style={styles.tableHeadLabel}>Valor</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && <FakeRowTable />}
            {!loading && data?.map(element => (
                <TableRow className={classes.tableRow}>
                  <TableCell align="left" className={classes.statusCell}>
                    {colorStatus(element.status)}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {element.type}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {element.companyName}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {convertBrazilianDate(element.creationDate)}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {element.paymentDate === null
                      ? '-'
                      : convertBrazilianDate(element.paymentDate)}
                  </TableCell>
                  <TableCell align="left" className={classes.defaulCell}>
                    {element.amount.toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                  <TableCell align="center" className={classes.defaulCell}>
                    {renderIconButton(element)}
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={totalSize}
                rowsPerPage={pageSize}
                page={currentPage}
                labelRowsPerPage="Linhas por página"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </MuiThemeProvider>
  );
};
