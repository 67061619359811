import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    form: {
      padding: theme.spacing(0, 2),
    },

    divider: {
      marginBottom: theme.spacing(4),
    },

    loader: {
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(5, 0),
    },
  }),
);
