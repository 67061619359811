export function useAutocompleteField() {
  function findValueInArray(array, valueField, getOptionValue) {
    if (!Array.isArray(array)) {
      console.warn('The provided array is not valid.');
      return null;
    }

    if (typeof getOptionValue !== 'function') {
      throw new Error('getOptionValue must be a function');
    }

    const selectedOption = array.find(
      option => getOptionValue(option) === valueField,
    );

    return selectedOption || null;
  }

  return { findValueInArray };
}
