import React from 'react';
import { Add } from '@material-ui/icons';
import { Header } from '../../../components/Header';
import Buttons from '../../../components/Buttons';

import { Tabs } from './components/Tabs';
import { useEmployeesList } from './hooks/useEmployeesList';

export function List() {
  const {
    history,
    handleEmployeesRegisterBatchOptionClick,
    currentTab,
    handleChangeTab,
    totalApprovedEmployees,
    totalPendingEmployees,
    totalReprovedEmployees,
  } = useEmployeesList();

  return (
    <>
      <Header
        title="Gerenciar funcionários"
        buttons={
          <Buttons.RoundedButton
            icon={<Add />}
            title="Incluir funcionários"
            options={[
              {
                title: 'Cadastrar individualmente',
                onClick: () => history.push('employees/register-single'),
              },
              {
                title: 'Cadastrar a partir de um arquivo',
                onClick: handleEmployeesRegisterBatchOptionClick,
              },
            ]}
          />
        }
      />

      <Tabs
        currentTab={currentTab}
        handleChangeTab={handleChangeTab}
        employeesTabsQuantities={{
          totalApprovedEmployees,
          totalPendingEmployees,
          totalReprovedEmployees,
        }}
      />
    </>
  );
}
