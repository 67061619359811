import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContentText,
  Box
} from '@material-ui/core';

// Status portabilidade

// Portabilidade (Doador) -> Usuário solicita a chave pix para outra instituição.
// Portabilidade (Solicitante) -> A chave já é do usuário, mas esta vinculada a outra instituição.
// Portabilidade (Doador) -> Outra pessoa solicita a chave pix.
// Reivindicação (Solicitante) -> Usuário Solicita sua chave a outra instituição.

import Buttons from '../../../../../components/Buttons';
import useStyles from './styles';

const RequestPortabilityCancel = NiceModal.create(
  ({ onSubmit }) => {

  const isLoading = useSelector(state => state.portabilityStatus.isLoading);
    
  const modal = useModal();
  const styles = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={modal.visible}
      onClose={modal.remove}
      PaperProps={{ className: styles.root }}
    >
      <Box className={styles.wrapper}>
        <DialogContentText className={styles.title}>
          Cancelar portabilidade?
        </DialogContentText>
        <DialogContentText className={styles.description}>
          Ao confirmar, cancelaremos o processo de portabilidade da chave.
        </DialogContentText>
      </Box>
      <DialogActions className={styles.buttonContainer}>
        <Buttons.PrimaryButton
          color="primary"
          variant=''
          onClick={modal.remove}
          title="NÃO"
          disabled={isLoading}
        />
        <Buttons.PrimaryButton
          color="secondary"
          onClick={()=>onSubmit()}
          title="SIM"
          loading={isLoading}
        />
      </DialogActions>
    </Dialog>
  );
});

export default RequestPortabilityCancel;
