import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        paddingInline: theme.spacing(3),
        width: 'fit-content',
        maxWidth: 500,
        height: '100%',
        overflow: 'auto'
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        '& .close-button-container': {
            marginLeft: 'auto',
        }
    },
    titleText: {
        color: theme.palette.text.primary,
        fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.fontWeightBold,
    },
    closeButton: {
        width: 24,
        height: 24,
        minHeight: 24,
        minWidth: 24,
        padding: 0,
        '& svg': {
            width: 14,
            height: 14,
        }
    },
}));

export default useStyles;