import React, { useState, useEffect } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Box, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { Alert } from '@material-ui/lab';

import Modals from '../../../../../../../components/Modals';
import Form from '../../../../../../../components/Form';
import CardTools from '../../../../../../../components/CardTools';
import Buttons from '../../../../../../../components/Buttons';

import formatCPF from '../../../../../../../utils/formatCPF';
import { useHandleEmployeeBankData } from './hooks/useHandleEmployeeBankData';
import { errors } from '../../../../../../../enums/registerUsersErrors';

export const HandleEmployeeBankData = NiceModal.create(
  ({ employee = {}, bankList = [], employeeType = '' }) => {
    const { modal, handleCloseModal, form } = useHandleEmployeeBankData({
      employee,
      employeeType,
    });

    const transferOption = form.watchedValues.paymentMethod;

    const shouldShowBankDetailsInputs =
      !form.errors.pixKey && !!form.shouldConfirmBankData;

    const shouldDisableBankDetailsInputs = transferOption === 'pix';

    const handleSubmitAction = () => {
      const actions = {
        pix: form.getBankInfo,
        pixViaBankDetails: form.handleSubmitBankData,
      };
      actions[transferOption]();
    };

    const shouldDisableInputSubmit = () => {
      const isPix = form.watchedValues.paymentMethod === 'pix';
      const isPixInvalid =
        !form.watchedValues.pixKey || !form.watchedValues.pixType;

      return isPix
        ? form.errors.pixKey || form.errors.pixType || isPixInvalid
        : !form.canSubmit || form.isLoading;
    };

    const getTextSubmitButton = () => {
      if (
        form.shouldConfirmBankData ||
        transferOption === 'pixViaBankDetails'
      ) {
        return 'Confirmar dados bancários';
      }

      return 'Prosseguir';
    };

    return (
      <Modals.DefaultModal
        hasBottomBar={false}
        open={modal.visible}
        onClose={() => handleCloseModal()}
        title="Dados bancários do funcionário"
      >
        <Form.FormBase>
          <Box
            style={{ width: 550 }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <CardTools.CardInfo title="Nome" description={employee?.name} />
              <CardTools.CardInfo
                title="CPF"
                description={formatCPF(employee?.cpf)}
              />
              <Alert severity="info">
                {!!transferOption
                  ? 'Os dados inseridos deverão ser do colaborador.'
                  : 'É necessário preencher todos os campos obrigatórios da opção de transferência.'}
              </Alert>
            </Box>

            <Grid item xs={12} sm={12} md={12}>
              <Controller
                name="paymentMethod"
                control={form.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.SelectField
                    label="Opção de transferência*"
                    options={form.paymentMethodsOptions}
                    error={form.errors?.paymentMethod}
                    helperText={form.errors?.paymentMethod?.message}
                    {...field}
                    onChange={value => {
                      field.onChange(value);
                    }}
                  />
                )}
              />
            </Grid>

            <Divider orientation="horizontal" />

            {transferOption === 'pix' && (
              <>
                <Grid
                  container
                  direction={{ sm: 'column', md: 'row' }}
                  spacing={2}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      name="pixType"
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Form.SelectField
                          label="Tipo de chave Pix*"
                          options={form.pixTypeOptions}
                          error={form.errors?.pixType}
                          helperText={form.errors?.pixType?.message}
                          {...field}
                          onChange={value => {
                            form.setValue('pixType', value);

                            if (value === 'cpf') {
                              form.setValue('pixKey', formatCPF(employee?.cpf));
                            } else {
                              form.setValue('pixKey', '');
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      name="pixKey"
                      control={form.control}
                      render={({ field, fieldState }) => (
                        <Form.MaskedField
                          label="Chave Pix*"
                          mask={form.getPixMasks(form.watchedValues.pixType)}
                          disabled={form.watchedValues.pixType === 'cpf'}
                          error={form.errors?.pixKey}
                          helperText={form.errors?.pixKey?.message}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {(transferOption === 'pixViaBankDetails' ||
              shouldShowBankDetailsInputs) && (
              <>
                <Grid
                  container
                  direction={{ sm: 'column', md: 'row' }}
                  spacing={2}
                >
                  {form.infoDict?.owner?.name && (
                    <Grid item xs={12} sm={12} md={12}>
                      <Form.TextField
                        label="Nome recebedor"
                        disabled={true}
                        value={form.infoDict?.owner?.name}
                      />
                    </Grid>
                  )}

                  {form.infoDict?.owner?.taxIdNumber && (
                    <Grid item xs={12} sm={12} md={12}>
                      <Form.MaskedField
                        label="Documento recebedor+"
                        disabled={true}
                        mask={
                          form.infoDict?.owner?.type === 'LEGAL_PERSON'
                            ? '00.000.000/0000-00'
                            : '000.000.000-00'
                        }
                        value={form.infoDict?.owner?.taxIdNumber}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      name="bank"
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Form.AutocompleteField
                          label="Bancos*"
                          options={form.bankOptions}
                          getOptionLabel={e => e?.participantName}
                          getOptionValue={e => e?.codIspb}
                          error={form.errors?.bank}
                          helperText={form.errors?.bank?.message}
                          disabled={shouldDisableBankDetailsInputs}
                          {...field}
                          value={form.watchedValues.bank}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      name="accountType"
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Form.SelectField
                          label="Tipo de conta*"
                          options={form.accountTypeOptions}
                          error={form.errors?.accountType}
                          helperText={form.errors?.accountType?.message}
                          disabled={shouldDisableBankDetailsInputs}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Controller
                      name="agency"
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Form.NumericField
                          label="Agência*"
                          precision={5}
                          error={form.errors?.agency}
                          helperText={form.errors?.agency?.message}
                          disabled={shouldDisableBankDetailsInputs}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Controller
                      name="agencyDigit"
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Form.NumericField
                          label="Dígito da agência*"
                          precision={2}
                          error={form.errors?.agencyDigit}
                          helperText={form.errors?.agencyDigit?.message}
                          disabled={shouldDisableBankDetailsInputs}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Controller
                      name="account"
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Form.NumericField
                          label="Número da conta*"
                          precision={12}
                          error={form.errors?.account}
                          helperText={form.errors?.account?.message}
                          disabled={shouldDisableBankDetailsInputs}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Controller
                      name="accountDigit"
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Form.NumericField
                          label="Dígito da conta*"
                          precision={2}
                          error={form.errors?.accountDigit}
                          helperText={form.errors?.accountDigit?.message}
                          disabled={shouldDisableBankDetailsInputs}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                gap: '1rem',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' },
              }}
            >
              <Buttons.PrimaryButton
                disabled={form.isLoading}
                variant={
                  useMediaQuery(useTheme().breakpoints.down('sm'))
                    ? 'outlined'
                    : 'text'
                }
                title="Cancelar"
                onClick={handleCloseModal}
              />

              <Buttons.PrimaryButton
                disabled={shouldDisableInputSubmit()}
                color="secondary"
                title={getTextSubmitButton()}
                loading={form.isLoading}
                variant="contained"
                onClick={handleSubmitAction}
              />
            </Box>
          </Box>
        </Form.FormBase>
      </Modals.DefaultModal>
    );
  },
);
