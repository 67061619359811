import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateEmployeeBankDataMutation } from '../../../../../../../../redux/store/Employees/services/pendingEmployees.api';
import { useGetBankListQuery } from '../../../../../../../../redux/store/Employees/services/pendingEmployees.api';
import { useGetPixInfoDictMutation } from '../../../../../../../../redux/store/Employees/services/employees';
import { handleSnackbarStack } from '../../../../../../../../utils/handleSnackbarStack';
import formatCPF from '../../../../../../../../utils/formatCPF';
import {
  validationSchema,
  getPixMasks,
  PIX_TYPE_OPTIONS,
  ACCOUNT_TYPE_OPTIONS,
  PAYMENT_METHODS_OPTIONS,
  ACCOUNT_TYPE_ENUM,
} from '../utils';

import { Employee } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentCompanieData } from '../../../../../../../../utils/getCurrentCompanieData';
import { PixInfoDictResponse } from '../../../../../../../../redux/store/Employees/services/employees/types';
import { reduxApi } from '../../../../../../../../services/reduxApi';
import { useReprovedEmployees } from '../../../../Tabs/ReprovedEmployees/Hooks/useReprovedEmployees';
import { usePendingEmployees } from '../../../../Tabs/PendingEmployees/Hooks/usePendingEmployees';

interface UseHandleEmployeeBankData {
  employee: Employee;
  employeeType: 'PENDING' | 'REPROVED';
}

export function useHandleEmployeeBankData({
  employee,
  employeeType,
}: UseHandleEmployeeBankData) {
  const modal = useModal();
  const { table: reprovedTable } = useReprovedEmployees();
  const { table: pendingTable } = usePendingEmployees();

  const [infoDict, setInfoDict] = useState<PixInfoDictResponse>();
  const {
    currentCompany: { code },
    companies,
  } = useSelector((state: any) => state.companies);
  const currentCompanyData = getCurrentCompanieData(code, companies);

  const { data: bankList } = useGetBankListQuery({});

  const [
    getPixInfoDictMutation,
    { isLoading: isLoadingPixInfo },
  ] = useGetPixInfoDictMutation();

  const [
    handleUpdateEmployeeBankData,
    { isLoading },
  ] = useUpdateEmployeeBankDataMutation();

  const setDefaultPaymentMethod = () => {
    if (!!employee.hasBankData && !!employee?.pixKeyData?.key) return 'pix';

    if (!!employee.hasBankData && !employee?.pixKeyData?.key)
      return 'pixViaBankDetails';

    return '';
  };

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    setValue,
    formState: { errors, isValid, dirtyFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  function handleCloseModal() {
    modal.hide();
    reset({
      paymentMethod: '',
      pixType: '',
      pixKey: '',
      bank: '',
      accountType: '',
      agency: '',
      agencyDigit: '',
      account: '',
      accountDigit: '',
    });
  }

  const findCodeBank = (participant: string, prop = 'codIspb') => {
    if (!participant || !prop || !bankList) return '';

    const result = bankList.find((bank: any) => bank[prop] === participant);
    return result?.codIspb || '';
  };

  const updateBankInfo = (data: PixInfoDictResponse) => {
    const accountDigit = data.account.accountNumber.slice(-1);
    const account = data.account.accountNumber.slice(0, -1);
    const codeBank = findCodeBank(data.account.participant);

    setValue('account', account);
    setValue('bank', codeBank);
    setValue('accountDigit', accountDigit);
    setValue('agency', data.account.branch);
    setValue(
      'accountType',
      ACCOUNT_TYPE_ENUM[
        data.account.accountType as keyof typeof ACCOUNT_TYPE_ENUM
      ],
    );
  };

  const isFilled = (obj: object | undefined | null) => {
    return !!obj && Object.keys(obj).length > 0;
  };

  async function getBankInfo() {
    const formValues = getValues();
    const pixKey =
      formValues.pixType === 'phone'
        ? `+55${formValues.pixKey}`
        : `${formValues.pixKey}`;

    if (isFilled(infoDict)) return onSubmitNewEmployeeBankData();

    try {
      const pixInfoDict = await getPixInfoDictMutation({
        key: pixKey,
        payerId: currentCompanyData.cnpj,
      }).unwrap();

      setInfoDict(pixInfoDict);
      updateBankInfo(pixInfoDict);
    } catch (_) {
      handleSnackbarStack().error(
        'Houve um erro ao buscar os dados bancários, tente mais tarde!',
      );
    }
  }

  async function onSubmitNewEmployeeBankData() {
    const formValues = getValues();

    const isBankData = formValues.paymentMethod === 'pixViaBankDetails';

    const payload = {
      id: employee?.id,
      employeeType,
      accountType: formValues.accountType,
      agency: formValues.agency,
      agencyDigit: formValues.agencyDigit,
      account: formValues.account,
      accountDigit: formValues.accountDigit,
      bankIspb: formValues.bank,
      useBankData: isBankData,
      ...(!isBankData && {
        endToEndId: infoDict?.endtoendid,
        pixKeyType: formValues.pixType,
        pixKey: formValues.pixKey,
        participant: infoDict?.account.participant,
      }),
    };

    try {
      await handleUpdateEmployeeBankData(payload as any).unwrap();
      handleSnackbarStack().success('Dados bancários inseridos com sucesso!');

      pendingTable.refetch();
      reprovedTable.refetch();
      modal.hide();
    } catch (_) {
      handleSnackbarStack().error(
        'Houve um erro ao inserir os dados bancários',
      );
    }
  }

  useEffect(() => {
    const codeOrIspbProp = employee?.bankIspb ? 'codIspb' : 'bankCode';
    const codeOrIspbValue = employee?.bankIspb
      ? employee?.bankIspb
      : employee?.bank;

    const currentIspBank = findCodeBank(codeOrIspbValue, codeOrIspbProp);

    reset({
      paymentMethod: setDefaultPaymentMethod(),
      pixType: employee?.pixKeyData?.type || '',
      pixKey: employee?.pixKeyData?.key || '',
      bank: currentIspBank,
      accountType: employee.accountType,
      agency: employee.agency,
      agencyDigit: employee.agencyDigit,
      account: employee.account,
      accountDigit: employee.accountDigit,
    });

    return () => {
      setInfoDict(undefined);
    };
  }, [employee]);

  return {
    modal,
    handleCloseModal,
    form: {
      control,
      watchedValues: {
        paymentMethod: watch('paymentMethod'),
        pixKey: watch('pixKey'),
        pixType: watch('pixType'),
        bank: watch('bank'),
      },
      setValue,
      canSubmit: isValid && isFilled(dirtyFields),
      accountTypeOptions: ACCOUNT_TYPE_OPTIONS,
      pixTypeOptions: PIX_TYPE_OPTIONS,
      bankOptions: bankList,
      getPixMasks,
      paymentMethodsOptions: PAYMENT_METHODS_OPTIONS,
      isLoading: isLoading || isLoadingPixInfo,
      formatCPF,
      errors,
      shouldConfirmBankData: isFilled(infoDict),
      handleSubmitBankData: handleSubmit(onSubmitNewEmployeeBankData),
      getBankInfo,
      infoDict,
    },
  };
}
