import { createSlice } from '@reduxjs/toolkit';

const portabilityStatusSlice = createSlice({
  name: 'portabilityStatus',
  initialState: {
    isLoading: false,
    error: null,
  },
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
  },
});

export const PortabilityStatusActions = portabilityStatusSlice.actions;
export const portabilityStatusReducer = portabilityStatusSlice.reducer;
export default portabilityStatusReducer;