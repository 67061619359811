import React, { useState, useRef } from 'react';
import { Box, Button, Menu, MenuItem } from '@material-ui/core';
import { ReactComponent as IconButtonDot } from '../../../../assets/iconButtonDot.svg';

import useStyles from './styles';

const KebabMenu = ({ onSelectItem, items }) => {
    const classes = useStyles();
    const buttonRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClickKebab = () => {
        setIsOpen(!isOpen);
    };

    const handleClickItem = (selected) => {
        onSelectItem(selected);
        handleClickKebab();
    };

    return (
        <Box className={classes.wrapper}>
            <Button
                ref={buttonRef}
                onClick={handleClickKebab}
                className={classes.kebabButton}
            >
                <IconButtonDot />
            </Button>
            <Menu
                anchorEl={buttonRef.current}
                open={isOpen}
                onClose={handleClickKebab}
                className={classes.kebabMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        padding: 0,
                    },
                }}
            >
                <Box className={classes.kebabItensContainer}>
                    {items.map((item) => {
                        if(item?.isHidden) return <></>

                        return (
                            <MenuItem key={item.key} disabled={item?.isDisabled} onClick={() => handleClickItem(item)}>
                                {item.label}
                            </MenuItem>
                        )
                    })}
                </Box>
            </Menu>
        </Box>
    );
};

export default KebabMenu;
