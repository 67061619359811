import { reduxApi } from '../../../../../services/reduxApi';
import { PixInfoDictBody, PixInfoDictResponse } from './types';

const employeesTags = {
  tag: 'EMPLOYEES',
  listAbstractTag: { type: 'EMPLOYEES', id: 'LIST' },
};

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [employeesTags.tag],
  })
  .injectEndpoints({
    endpoints: builder => ({
      getBankList: builder.query({
        query: () => ({
          url: '/pix/key/banks',
        }),
      }),

      getEmployeesListQuantity: builder.query({
        query: companyCode => ({
          url: `/employees/count/${companyCode}`,
        }),
      }),

      getPixInfoDict: builder.mutation<PixInfoDictResponse, PixInfoDictBody>({
        query: ({ key, payerId }) => ({
          url: `/employees/pix-key/info/dict`,
          method: 'POST',
          body: {
            key,
            payerId,
          },
        }),
      }),
    }),
  });

export const {
  useGetBankListQuery,
  useGetPixInfoDictMutation,
  useLazyGetEmployeesListQuantityQuery,
} = slice;
