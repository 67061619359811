import React from 'react';
import { Box, CircularProgress, Divider } from '@material-ui/core';
import { AccountBalanceRounded } from '@material-ui/icons';

import { Header } from '../../components/Header';
import TransactionAccordion from './components/TransactionAccordion';
import FormAccordion from './components/FormAccordion';
import LimitForm from './components/LimitForm';

import RequestScreenModals from './components/Modals'

import { ReactComponent as PixIcon } from '../../assets/pix.svg';
import { ReactComponent as BarCodeIcon } from '../../assets/Icons/missing/barcode-scanner.svg';

import { PERIOD_TYPES, PERSON_TYPES, TRANSFER_TYPES } from './constants';
import useManageLimits from './hooks/useManageLimits';

import { useStyles } from './styles';

export default function ManageLimitsPage() {
  const styles = useStyles();

  const { isFetching } = useManageLimits();

  return (
    <>
      <Header
        title="Gerenciar limites"
        subtitle="Ajuste os limites das transações em cada período do dia"
      />
      <Box className={isFetching && styles.loader}>
        {isFetching ? (
          <CircularProgress color="primary" />
        ) : (
          <>
            <TransactionAccordion title="Pix" icon={<PixIcon fill="primary" />}>
              <FormAccordion title="Pessoa física">
                <LimitForm
                  person={PERSON_TYPES.PHYSICAL_PERSON}
                  type={TRANSFER_TYPES.PIX}
                  period={PERIOD_TYPES.DIURNAL}
                />
                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  className={styles.divider}
                />
                <LimitForm
                  person={PERSON_TYPES.PHYSICAL_PERSON}
                  type={TRANSFER_TYPES.PIX}
                  period={PERIOD_TYPES.NOTURNAL}
                />
              </FormAccordion>
              <FormAccordion title="Pessoa jurídica">
                <LimitForm
                  person={PERSON_TYPES.LEGAL_PERSON}
                  type={TRANSFER_TYPES.PIX}
                  period={PERIOD_TYPES.DIURNAL}
                />
                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  className={styles.divider}
                />
                <LimitForm
                  person={PERSON_TYPES.LEGAL_PERSON}
                  type={TRANSFER_TYPES.PIX}
                  period={PERIOD_TYPES.NOTURNAL}
                />
              </FormAccordion>
            </TransactionAccordion>
            <TransactionAccordion
              title="Boleto"
              icon={<BarCodeIcon fill="primary" />}
            >
              <LimitForm
                type={TRANSFER_TYPES.BILL_PAYMENT}
                period={PERIOD_TYPES.DIURNAL}
              />
              <Divider
                orientation="horizontal"
                variant="fullWidth"
                className={styles.divider}
              />
              <LimitForm
                type={TRANSFER_TYPES.BILL_PAYMENT}
                period={PERIOD_TYPES.NOTURNAL}
              />
            </TransactionAccordion>
            <TransactionAccordion
              title="Transferência entre contas Somapay"
              icon={<AccountBalanceRounded color="primary" />}
            >
              <LimitForm
                type={TRANSFER_TYPES.INTERNAL_TRANSFER}
                period={PERIOD_TYPES.DIURNAL}
              />
              <Divider
                orientation="horizontal"
                variant="fullWidth"
                className={styles.divider}
              />
              <LimitForm
                type={TRANSFER_TYPES.INTERNAL_TRANSFER}
                period={PERIOD_TYPES.NOTURNAL}
              />
            </TransactionAccordion>
          </>
        )}
      </Box>
      <RequestScreenModals />
    </>
  );
}
