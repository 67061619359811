import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    subtitle: {
      fontSize: '1rem',
      fontWeight: 500,
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(2),

      '& strong': {
        fontWeight: 700,
      },
    },
    button: {
      borderRadius: '50px',
      padding: `12px ${theme.spacing(3)}px`,
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    list: {
      padding: 0,
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: theme.spacing(2),
      marginBottom: '100px'
    },
  }),
);
