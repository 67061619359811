import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: '1rem 1.5rem',
      width: '30%',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 700,
      marginBottom: '1.5rem',
    },
    tabLabel: {
      textTransform: 'none',
      padding: '0.75rem',
    },
    tabPanel: {
      padding: '2rem 0',
    },
    valueCard: {
      border: '0.8px solid #E5E5E5',
      borderRadius: '6px',
      padding: '1rem',
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    divider: {
      margin: '1.5rem 0',
    },
    avatarBox: {
      display: 'flex',
      flexFlow: 'row nowrap',
      gap: '0.5rem',
      padding: '1rem 0',
    },
    labelText: {
      color: '#808080',
      fontSize: '0.875rem',
    },
    cashoutLimitBox: {
      marginLeft:'auto'
    },
    amountText: {
      fontSize: '1.25rem',
      fontWeight: 700,
      color: '#1A1A1A',
    },
    pixText: {
      display: 'inline-flex',
      gap: '6px',
      alignItems: 'center',
      fontWeight: 500,
    },

    avatar: {
      backgroundColor: 'rgba(82, 25, 127, 0.1)',
    },
    statusRow: {
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    approvedChip: {
      color: '#19797F',
      backgroundColor: '#E8F2F2',
      fontWeight: 500,
    },
    reprovedChip: {
      color: '#CA2317',
      backgroundColor: '#FAE9E8',
      fontWeight: 500,
    },
    dateContainer: {
      margin: '1.5rem 0',
    },
    dateText: {
      marginTop: '6px',
      color: '#1A1A1A',
      fontWeight: 500,
    },
    authorizers: {
      display: 'flex',
      gap: '1.5rem',
      flexFlow: 'column wrap',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    authorizersTitle: {
      display: 'flex',
      gap: '6px',
      alignItems: 'center',
    },
    authorizersHeading: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    authorizersChip: {
      backgroundColor: '#EEE8F2',
      fontSize: '0.75rem',
      fontWeight: 500,
      width: '20px',
      height: '20px',
      textAlign: 'center',
      borderRadius: '50%',
    },
    authorizersContainer: {
      display: 'flex',
      gap: '1.25rem',
      flexFlow: 'column wrap',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    authorizerEntry: {
      display: 'flex',
      flexFlow: 'row nowrap',
      gap: '0.75rem',
    },
    authorizerEntryText: {
      display: 'flex',
      gap: '0.5rem',
      flexFlow: 'column wrap',
      alignItems: 'flex-start',
    },
    authorizerApprovedCaption: {
      display: 'inline-flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      gap: '6px',
      alignItems: 'center',
      color: theme.palette.success.main,
      fontWeight: 500,
    },
    authorizerReprovedCaption: {
      display: 'inline-flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      gap: '6px',
      alignItems: 'center',
      color: theme.palette.error.main,
      fontWeight: 500,
    },
    authorizerIcon: {
      width: '1rem',
      height: '1rem',
    },
    authorizerName: {
      fontWeight: 500,
    },
    errorReason: {
      marginBottom: '1rem',
    },
    cancelContainer: {
      backgroundColor: '#FAFAFA',
      width: '100%',
      padding: '1rem',
    },
    cancelTitle: {
      color: '#808080',
      fontSize: '1rem',
      fontWeight: 500,
    },
    cancelReason: {
      color: '#1A1A1A',
      fontSize: '1rem',
      fontWeight: 500,
    },
    receiverTitle: {
      fontSize: '1.25rem',
      fontWeight: 700,
      marginBottom: '1.5rem',
    },
    receiverDataContainer: {
      marginBottom: '0.25rem',
    },
    receiverLabel: {
      fontSize: '0.75rem',
      fontWeight: 500,
      color: '#808080',
    },
    receiverData: {
      fontSize: '1rem',
      color: '#1A1A1A',
      fontWeight: 500,
    },
  }),
);
