import * as Yup from 'yup';

function validateCPF(cpf?: string) {
  cpf = cpf?.replace(/\D/g, '');

  if (!cpf) return false;

  if (cpf.length !== 11) return false;

  if (/^(\d)\1{10}$/.test(cpf)) return false;

  const numArray = cpf.split('').map(Number);

  let verifier1 =
    numArray
      .slice(0, 9)
      .reduce((acc, num, index) => acc + num * (10 - index), 0) % 11;
  verifier1 = verifier1 < 2 ? 0 : 11 - verifier1;

  let verifier2 =
    numArray
      .slice(0, 10)
      .reduce((acc, num, index) => acc + num * (11 - index), 0) % 11;
  verifier2 = verifier2 < 2 ? 0 : 11 - verifier2;

  return verifier1 === numArray[9] && verifier2 === numArray[10];
}

type PaymentMethodValidationSchema = 'pixViaBankDetails' | 'pix';

const paymentMethodValidationSchema = Yup.object({
  pixViaBankDetails: Yup.string(),
  pix: Yup.string().required('Tipo de Pix é obrigatório'),
});

type KeysValidationSchema = 'cpf' | 'cnpj' | 'phone' | 'email' | 'random_key';

const pixKeyValidationSchema = Yup.object({
  cpf: Yup.string()
    .test('cpf', 'CPF inválido', value => validateCPF(value))
    .required('CPF é obrigatório'),
  cnpj: Yup.string()
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$|^\d{14}$/, 'CNPJ inválido')
    .required('CNPJ é obrigatório'),
  phone: Yup.string()
    .matches(
      /^(?:\(\d{2}\)\s?\d{5}-\d{4}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}|\d{11})$/,
      'Telefone inválido',
    )
    .required('Telefone é obrigatório'),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'E-mail inválido',
    )
    .required('E-mail é obrigatório'),
  random_key: Yup.string().required('Chave aleatória é obrigatória'),
});

export const validationSchema = Yup.object().shape({
  paymentMethod: Yup.string().required('Opção de transferência é obrigatória'),
  pixType: Yup.string().when('paymentMethod', (paymentMethod, schema): any => {
    const key: PaymentMethodValidationSchema = paymentMethod[0];
    return paymentMethodValidationSchema.fields[key] || schema;
  }),
  pixKey: Yup.string().when('pixType', (pixType, schema): any => {
    const key: KeysValidationSchema = pixType[0];
    return pixKeyValidationSchema.fields[key] || schema;
  }),
  bank: Yup.string().required('Banco é obrigatório'),
  accountType: Yup.string().required('Tipo de conta é obrigatório'),
  agency: Yup.string().required('Agência é obrigatória'),
  agencyDigit: Yup.string().required('Dígito da agência é obrigatório'),
  account: Yup.string().required('Conta é obrigatória'),
  accountDigit: Yup.string().required('Dígito da conta é obrigatório'),
});
