import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Controller } from 'react-hook-form';
import Form from '../../../../../components/Form';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Buttons from '../../../../../components/Buttons';
import useIncreaseValueForm from '../../LimitForm/hooks/useIncreaseValueForm';

import useStyles from './styles';

import { PERIOD_MAP, TRANSFER_MAP, PERIOD_HOUR_MAP, PERSON_MAP } from './constants';
  
const RequestIncreaseValueModal = NiceModal.create(({ period, type, maxLimit, person }) => {
    const modal = useModal();
    const styles = useStyles();

    const {
        control,
        formState:{ errors },
        isLoading,
        errorRequest,
        onSubmit
    } = useIncreaseValueForm({
        maxLimit,
        period, 
        type,
        person
    })

    const handleClose = () => {
        modal.remove();
    };

    const renderInfoMessage = () => {
        const typeInfo = !!errorRequest ? 'error' : 'info'
        const messageMap = {
            error: errorRequest?.data?.errors[0]?.errorDetail,
            info: <> Ao solicitar o aumento de limite, a aprovação pelos autorizadores será necessária na tela de <b className={styles.infoEmphasis}>Gerenciar Solicitações</b> para que o novo limite entre em vigor. </>
        }
        
        return (
            <Alert className={styles.infoContainer} severity={typeInfo}>
                {messageMap[typeInfo]}
            </Alert>
        )

    }

    return (
        <Dialog
            maxWidth="sm"
            open={modal.visible}
            onClose={handleClose}
            PaperProps={{ className: styles.root }}
        >   
        
            <DialogTitle disableTypography className={styles.title}>
                Solicitar aumento de limite <b className={styles.descriptionType}>{PERIOD_MAP[period] || ''} para {TRANSFER_MAP[type] || ''}</b>
            </DialogTitle>

            <DialogContentText className={styles.contentText}>
                Digite o valor que deseja receber via {TRANSFER_MAP[type]}{PERSON_MAP[person]}, disponível entre {PERIOD_HOUR_MAP[period] || '-'}.
            </DialogContentText>
        
            <DialogContent className={styles.content}>
                
                {renderInfoMessage()}

                <Form.FormBase onSubmit={onSubmit}>
                    <Box className={styles.boxInput}>
                        <Controller 
                            name='requestValue'
                            control={control}
                            render={({ field }) => (
                                <Form.CurrencyField
                                    className={styles.input}
                                    size="small"
                                    label="Limite"
                                    error={errors?.requestValue}
                                    helperText={errors?.requestValue?.message}
                                    {...field}
                                />
                            )}
                        />
                    </Box>

                    <DialogActions className={styles.actions}>
                        <Buttons.PrimaryButton
                            color="primary"
                            variant="text"
                            title="CANCELAR"
                            onClick={handleClose}
                        />
                        <Buttons.PrimaryButton
                            type="submit"
                            color="secondary"
                            title="SOLICITAR AUMENTO"
                            disabled={!!errors.requestValue || isLoading}
                            loading={isLoading}
                        />
                    </DialogActions>
                </Form.FormBase>
            </DialogContent>
        </Dialog>
    );
});

export default RequestIncreaseValueModal;
