import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';
import debounce from 'lodash.debounce';

import { CashOutActions as Actions } from '../../../../redux/store/CashOut';

export default function useManageRequests({ status }) {
  const dispatch = useDispatch();

  const {
    results,
    isLoading,
    filters,
    pagination,
    selectedResult,
    actionResults: { auth, approval, reproval, reprocess },
  } = useSelector(state => state.cashOut);

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: filters,
  });

  const onSubmit = form => {
    dispatch(
      Actions.changePage({
        page: 0,
      }),
    );

    dispatch(Actions.setFilters(form));
    dispatch(Actions.getRequestsCount());
    dispatch(Actions.getRequestsList({ status }));
  };

  const onSearch = useCallback(
    debounce(() => handleSubmit(onSubmit)(), 500),
    [],
  );

  const onChangePage = (page, perPage) => {
    dispatch(
      Actions.changePage({
        page,
        perPage,
      }),
    );
    dispatch(Actions.getRequestsList({ status }));
  };

  const onSubmitReproval = form => {
    dispatch(Actions.setRequestRejected(form));
  };

  const onSubmitReprocess = () => {
    dispatch(Actions.setAuthentication());
    NiceModal.show('mfa-request', {
      onSubmit: form => dispatch(Actions.setRequestReprocessed(form)),
      onResend: () => dispatch(Actions.setAuthentication()),
      onClose: onCloseModal,
    });
  };

  const onSubmitMfa = form => {
    dispatch(Actions.setRequestApproved(form));
  };

  const onCloseModal = () => {
    dispatch(Actions.resetErrors());
    dispatch(Actions.getRequestsCount());
    dispatch(Actions.getRequestsList({ status }));
  };

  const onSubmitApproval = () => {
    dispatch(Actions.setAuthentication());
    NiceModal.show('mfa-request', {
      onSubmit: onSubmitMfa,
      onResend: () => dispatch(Actions.setAuthentication()),
      onClose: onCloseModal,
    });
  };

  const onOpenDetails = selected => {
    dispatch(Actions.setSelected(selected));
  };

  const onOpenApproveModal = selected => {
    dispatch(Actions.setSelected(selected));
    NiceModal.show('approve-request', {
      onSubmit: onSubmitApproval,
      onClose: onCloseModal,
      selected,
    });
  };

  const onOpenReproveModal = selected => {
    dispatch(Actions.setSelected(selected));
    NiceModal.show('reprove-request', {
      onSubmit: onSubmitReproval,
      onClose: onCloseModal,
      selected,
    });
  };

  const onOpenReprocessModal = selected => {
    dispatch(Actions.setSelected(selected));
    NiceModal.show('approve-request', {
      onSubmit: onSubmitReprocess,
      onClose: onCloseModal,
      selected,
    });
  };

  useEffect(() => {
    if (auth.hasSuccess) {
      NiceModal.remove('approve-request');
    }
  }, [auth, dispatch]);

  useEffect(() => {
    if (approval.hasSuccess) {
      NiceModal.remove('mfa-request');
      const approveTimeout = setTimeout(() => {
        dispatch(Actions.getRequestsList({ status }));
        dispatch(Actions.getRequestsCount());
      }, 1000);

      return () => clearTimeout(approveTimeout);
    }
  }, [approval, dispatch]);

  useEffect(() => {
    if (reprocess.hasSuccess) {
      NiceModal.remove('reprocess-request');
      NiceModal.remove('approve-request');
      NiceModal.remove('mfa-request');

      const reprocessTimeout = setTimeout(() => {
        dispatch(Actions.getRequestsList({ status }));
        dispatch(Actions.getRequestsCount());
      }, 1000);

      return () => clearTimeout(reprocessTimeout);
    }
  }, [reprocess, dispatch]);

  useEffect(() => {
    if (reproval.hasSuccess) {
      NiceModal.remove('reprove-request');
      const reproveTimeout = setTimeout(() => {
        dispatch(Actions.getRequestsList({ status }));
        dispatch(Actions.getRequestsCount());
      }, 1000);

      return () => clearTimeout(reproveTimeout);
    }
  }, [reproval, dispatch]);

  useEffect(() => {
    dispatch(Actions.getRequestsList({ status }));
    dispatch(Actions.getRequestsCount());
  }, []);

  return {
    results,
    length: pagination.length,
    isLoading,
    control,
    onChangePage,
    onOpenDetails,
    onOpenApproveModal,
    onOpenReproveModal,
    onOpenReprocessModal,
    selected: selectedResult,
    onSearch,
  };
}
