import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import { ReactComponent as CalendarAndClock } from '../../../../../assets/calendarAndClock.svg';
import Buttons from '../../../../../components/Buttons';
import useStyles from './styles';

const RequestPortabilitySuccess = NiceModal.create(({ onSubmit }) => {
  const modal = useModal();
  const styles = useStyles();
  
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={modal.visible}
      onClose={modal.remove}
      PaperProps={{ className: styles.root }}
    >
      <CalendarAndClock className={styles.icon} />
      <DialogContent className={styles.wrapper}>
        <Typography variant='h2' className={styles.title}>Portabilidade solicitada</Typography>
        <Typography variant='h5' className={styles.description}> Agora você precisa confirmar a portabilidade na instituição onde a chave está cadastrada em até 7 dias. </Typography>
        <Typography variant='h5' className={styles.description}> Após conclusão do processo de portabilidade, você receberá um e-mail informado sobre o resultado da solicitação.</Typography>
      </DialogContent>
      <DialogActions className={styles.buttonContainer}>
        <Buttons.PrimaryButton
          color="primary"
          onClick={()=>onSubmit()}
          title="ENTENDI"
        />
      </DialogActions>
    </Dialog>
  );
});

export default RequestPortabilitySuccess;
