import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  limits: [],
};

const LimitManagementSlice = createSlice({
  name: 'limitManagement',
  initialState,
  reducers: {
    setLimits: (state, { payload }) => {
      state.limits = payload;
    },
    clearLimits: state => {
      state.limits = initialState.limits;
    },
    updateNewLimitRequested: (state, { payload }) => {
      const { type, personType, period, newLimit } = payload;

      const limit = state.limits.find(limit =>
        limit.type === type &&
        limit.personType === personType &&
        limit.period === period
      );

      if (limit) {
        limit.newLimitRequested = newLimit;
      }
    },
  },
});

const limitManagementReducer = LimitManagementSlice.reducer;
const LimitManagementActions = {
  ...LimitManagementSlice.actions,
};

export { limitManagementReducer, LimitManagementActions };
