import React, { useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Box, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryModal from '../../../../../../../../components/PrimaryModal';
import Form from '../../../../../../../../components/Form';
import CardTools from '../../../../../../../../components/CardTools';
import Buttons from '../../../../../../../../components/Buttons';
import { ActionsOnboardingReprovedGeneral } from '../../../../../../../../redux/store/Employees/OnboardingReproved';
import { handleSnackbarStack } from '../../../../../../../../utils/handleSnackbarStack';

export const BankDataModal = NiceModal.create(({ rowEmployeeData = {} }) => {
  const dispatch = useDispatch();
  const {
    employeeData,
    bankListHandling: { bankList },
  } = useSelector(state => state.employees.onboardingReprovedGeneral);

  const modal = useModal();

  const { control, handleSubmit, getValues, watch, reset } = useForm({
    values: {
      bank: rowEmployeeData?.bank,
      accountType: rowEmployeeData.accountType,
      agency: rowEmployeeData?.agency,
      agencyDigit: rowEmployeeData?.agencyDigit,
      account: rowEmployeeData?.account,
      accountDigit: rowEmployeeData?.accountDigit,
    },
  });

  const accountTypeOptions = [
    {
      label: 'Conta Corrente',
      value: 'CC',
    },
    {
      label: 'Conta Poupança',
      value: 'CP',
    },
  ];

  watch();

  const isAbleToSubmit =
    getValues('bank') &&
    getValues('accountType') &&
    getValues('agency') &&
    getValues('agencyDigit') &&
    getValues('account') &&
    getValues('accountDigit');

  function handleCloseBankDataModal() {
    modal.hide();
    reset();
  }

  function handleUpdateEmployeeBankData(newBankData) {
    dispatch(
      ActionsOnboardingReprovedGeneral.updateEmployeeBankData({
        ...newBankData,
        id: rowEmployeeData?.id,
        employeeType: rowEmployeeData?.employeeType,
      }),
    );
  }

  useEffect(() => {
    if (employeeData?.successOn?.hasSuccess) {
      handleCloseBankDataModal();
      handleSnackbarStack().success(
        'Os dados bancários foram enviados com sucesso!',
      );
      dispatch(
        ActionsOnboardingReprovedGeneral.searchOnboardingReprovedEmployees(),
      );
    }
  }, [employeeData?.successOn]);

  return (
    <PrimaryModal
      fullscreen={useMediaQuery(useTheme().breakpoints.down('sm'))}
      open={modal.visible}
      title="Dados bancários do funcionários"
      text="Todos os dados bancários precisam estar preenchidos para serem enviados."
    >
      <Form.FormBase
        onSubmit={() => handleSubmit(handleUpdateEmployeeBankData)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <CardTools.CardInfo
              title="Nome"
              description={rowEmployeeData?.employeeName}
            />
            <CardTools.CardInfo
              title="CPF"
              description={rowEmployeeData?.employeeCpf}
            />
          </Box>

          <Divider orientation="horizontal" />

          <Grid container direction={{ sm: 'column', md: 'row' }} spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Controller
                name="bank"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.AutocompleteField
                    label="Banco*"
                    options={bankList}
                    getOptionLabel={e => e?.name}
                    getOptionValue={e => e?.code}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Controller
                name="accountType"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.SelectField
                    label="Tipo de conta*"
                    options={accountTypeOptions}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="agency"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.NumericField
                    label="Agência*"
                    precision={5}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="agencyDigit"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.NumericField
                    label="Dígito da agência*"
                    precision={2}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="account"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.NumericField
                    label="Número da conta*"
                    precision={12}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="accountDigit"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.NumericField
                    label="Dígito da conta*"
                    precision={2}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          {employeeData?.errorOn?.hasError && (
            <Alert severity="error">{employeeData?.errorOn?.message}</Alert>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              gap: '1rem',
              flexDirection: { xs: 'column', sm: 'column', md: 'row' },
            }}
          >
            <Buttons.SecondaryButton
              variant={
                useMediaQuery(useTheme().breakpoints.down('sm'))
                  ? 'outlined'
                  : 'text'
              }
              title="Cancelar"
              onClick={handleCloseBankDataModal}
            />

            <Buttons.PrimaryButton
              disabled={!isAbleToSubmit}
              type="submit"
              title="Confirmar"
              loading={employeeData?.isLoading}
            />
          </Box>
        </Box>
      </Form.FormBase>
    </PrimaryModal>
  );
});
