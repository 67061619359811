import React from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import {
  CheckOutlined,
  CloseOutlined,
  VisibilityOutlined,
} from '@material-ui/icons';

import Buttons from '../../../../../../../components/Buttons';
import { ChipStatus } from '../../../../../../../components/ChipStatus';

import { renderRequestType } from '../../../../utils';

import currencyFormatter from '../../../../../../../utils/currencyFormatter';

import useStyles from '../styles';

export default function useTableConfig({
  handleDetails,
  onOpenApproveModal,
  onOpenReproveModal,
}) {
  const styles = useStyles();

  const renderApprovals = row => {
    const { numberOfApprovals, numberOfRequiredApprovals } = row;

    return (
      <ChipStatus
        label={`${numberOfApprovals} de ${numberOfRequiredApprovals}`}
        size="small"
        className={styles.approvalChip}
        variant="default"
      />
    );
  };

  const renderActions = row => {
    return (
      <Box className={styles.iconsContainer}>
        <Buttons.IconButton
          className={styles.iconButton}
          tooltipTitle="Autorizar solicitação"
          icon={
            <CheckOutlined className={styles.successIcon} fontSize="small" />
          }
          onClick={() => onOpenApproveModal(row)}
        />
        <Buttons.IconButton
          className={styles.iconButton}
          tooltipTitle="Reprovar solicitação"
          icon={<CloseOutlined color="error" fontSize="small" />}
          onClick={() => onOpenReproveModal(row)}
        />
        <Buttons.IconButton
          className={styles.iconButton}
          tooltipTitle="Visualizar detalhes"
          icon={<VisibilityOutlined color="primary" fontSize="small" />}
          onClick={() => handleDetails(row)}
        />
      </Box>
    );
  };

  return [
    {
      key: 'creationUser',
      type: 'field',
      align: 'left',
      label: 'Solicitante',
    },
    {
      key: 'requestType',
      type: 'field',
      align: 'left',
      label: 'Tipo de solicitação',
      render: row => renderRequestType(row),
    },
    {
      key: 'creationDate',
      type: 'field',
      align: 'left',
      label: 'Data da solicitação',
      render: row => moment(row.creationDate).format('DD/MM/YYYY'),
    },
    {
      key: 'approvals',
      type: 'field',
      align: 'left',
      label: 'Autorizações',
      render: row => renderApprovals(row),
    },
    {
      key: 'amount',
      type: 'field',
      align: 'left',
      label: 'Valor',
      render: row => currencyFormatter(row.amount),
    },
    {
      key: 'actions',
      type: 'field',
      align: 'center',
      customLabel: () => (
        <Box sx={{ width: '100%' }} className={styles.iconsContainer}>
          Ações
        </Box>
      ),
      render: row => renderActions(row),
    },
  ];
}
