import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, IconButton, Tooltip } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import { ActionsManualPayroll } from '../../../../../../../../../../redux/store/Payroll/ManualPayroll';
import { useInsertBankData } from '../../InsertBankDataDrawer/Hooks/useInsertBankData';
import formatCPF from '../../../../../../../../../../utils/formatCPF';

import { useGetBankListQuery } from '../../../../../../../../../../redux/store/Employees/services/employees';

export const useTableConfig = () => {
  const dispatch = useDispatch();
  const { insertBankDataDrawer, banksList } = useInsertBankData();

  const { data: bankList, isLoading: isLoadingBankList } = useGetBankListQuery(
    {},
  );

  const handleInsertBankData = row => {
    insertBankDataDrawer.handleOpen();
    dispatch(
      ActionsManualPayroll.setSelectedEmployeeToInsertBankData({
        ...row,
        employeeType: 'REPROVED',
      }),
    );
  };

  const findCodeBank = (participant, prop = 'codIspb') => {
    if (!participant || !prop || !bankList) return '-';

    const result = bankList.find(bank => bank[prop] === participant);
    return result?.participantName || '-';
  };

  const handleFindBankName = employee => {
    const codeOrIspbProp = employee?.bankIspb ? 'codIspb' : 'bankCode';
    const codeOrIspbValue = employee?.bankIspb
      ? employee?.bankIspb
      : employee?.bank;

    const currentIspBank = findCodeBank(codeOrIspbValue, codeOrIspbProp);

    return currentIspBank;
  };

  const handleConcatAgencyWithDigit = row =>
    `${row?.agency}-${row?.agencyDigit}`;

  const handleConcatAccountWithDigit = row =>
    `${row?.account}-${row?.accountDigit}`;

  useEffect(() => {
    insertBankDataDrawer.handleClose();
  }, []);

  const tableConfig = [
    {
      key: 'name',
      type: 'field',
      label: 'Nome',
      align: 'left',
      render: row => row?.name ?? '-',
    },
    {
      key: 'cpf',
      type: 'field',
      label: 'CPF',
      align: 'left',
      render: row => (row?.cpf ? formatCPF(row?.cpf) : '-'),
    },
    {
      key: 'bank',
      type: 'field',
      label: 'Banco',
      align: 'left',
      render: row => handleFindBankName(row),
    },
    {
      key: 'agency',
      type: 'field',
      label: 'Agência',
      align: 'left',
      render: row =>
        row?.agency && row?.agencyDigit
          ? handleConcatAgencyWithDigit(row)
          : '-',
    },
    {
      key: 'account',
      type: 'field',
      label: 'Conta',
      align: 'left',
      render: row =>
        row?.account && row?.accountDigit
          ? handleConcatAccountWithDigit(row)
          : '-',
    },
    {
      key: 'actions',
      type: 'field',
      label: '',
      align: 'left',
      render: row => (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Tooltip
            arrow
            title="Não é possível selecionar um colaborador sem dados bancários. Clique para inseri-los."
          >
            <IconButton
              color="primary"
              onClick={() => handleInsertBankData(row)}
            >
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return {
    tableConfig,
  };
};
