export const getPixMasks = (pixType: string) => {
  switch (pixType) {
    case 'CPF':
      return '000.000.000-00';
    case 'CNPJ':
      return '00.000.000/0000-00';
    case 'PHONE':
      return '(00) 00000-0000';
    default:
      return null;
  }
};
