import { makeStyles, alpha } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingBlockStart: theme.spacing(3),
    height: '100%',
    overflow: 'auto',
    gap: theme.spacing(2),
  },
  statusKeyTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[900],
  },
  avatarContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    '& span, & p': {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  avatar: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    borderRadius: theme.shape.borderRadius,
  },
  infoContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12),
  },
  content: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
  },
  cardColumm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardIconContainer: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    background: alpha(theme.palette.primary.dark, 0.1),
    borderRadius: theme.shape.borderRadius,
  },
  cardTitle: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[600],
  },
  cardText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[800],
  },
  cardStatusText: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.warning.main, 
    paddingInline: theme.spacing(1),
    borderRadius: 50,
    backgroundColor: alpha(theme.palette.warning.light, 0.3), 
  },
  notificationCard: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#EAEEF8',
    gap: theme.spacing(2),

    '& svg':{
      minWidth: 24,
    },
  },
  notificationTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  notificationText: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#003768',
  },
  historyTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
  },
  historyStepContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    gap: theme.spacing(1),
    minHeight: 100,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.grey[200],
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[500],
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.grey[700],
    },
  },
  historyStepCard: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  historyIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& .icon-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 32,
      height: 32,
      minHeight: 32,
      borderRadius: '50%',
      backgroundColor: alpha(theme.palette.warning.light, 0.3),

      '&.check':{
        backgroundColor: '#E8F2F2',
      },

      '& svg':{
        width: '100%',
      },
    },
    '& .divider': {
      width: 2,
      height: '90%',
      backgroundColor: theme.palette.divider,
    },
  },
  historyTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    gap: theme.spacing(1),
    paddingBlock: theme.spacing(0.5),
    '& .title': {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.grey[900],
    },
    '& .content': {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.grey[600],
    },
    '& .date': {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.grey[600],
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 'auto',
    "& p": {
      fontWeight: 500,
    }
  }
}));

export default useStyles;