import React from 'react';
import { ModalDef } from '@ebay/nice-modal-react';

import RequestIncreaseValueModal from './RequestIncreaseValueModal';

export default function RequestScreenModals() {
  return (
    <>
      <ModalDef id="request-increase-value" component={RequestIncreaseValueModal} />
    </>
  );
}
