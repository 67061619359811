import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { FilterHeader } from './Components/FilterHeader';
import { FilterBottom } from './Components/FilterBottom';
import { FilterMainFields } from './Components/FilterMainFields';
import { FilterExtraFields } from './Components/FilterExtraFields';
import { useDesktopMode } from './Hooks/useDesktopMode';
import Form from '../../../Form';

export function DesktopMode({
  children,
  defaultValues = {},
  validation = null,
  handleRequests = () => {},
}) {
  const { filterComponentObject, form } = useDesktopMode({
    fields: children,
    defaultValues,
    validation,
  });

  return (
    <Form.FormBase
      id="filter-form"
      onSubmit={form.handleSubmit(handleRequests)}
    >
      <Paper
        style={{
          marginBottom: '1rem',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 10px #3b35411f',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FilterHeader />

          <FilterMainFields
            fields={filterComponentObject.mainFields}
            control={form.control}
            hasMoreFields={filterComponentObject.extraFields?.length > 0}
            isViewingMoreFields={filterComponentObject.isViewingMoreFields}
            onViewMoreFields={filterComponentObject.handleIsViewingMoreFields}
          />

          <FilterExtraFields
            fields={filterComponentObject.extraFieldsFields}
            control={form.control}
            isViewingMoreFields={filterComponentObject.isViewingMoreFields}
          />

          <FilterBottom
            hasMandatoryFields={filterComponentObject.hasMandatoryFields}
            isClearFieldsDisabled={filterComponentObject.isClearFieldsDisabled}
            onClearFields={filterComponentObject.handleClearFields}
          />
        </Box>
      </Paper>
    </Form.FormBase>
  );
}
