import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),

        '& > div': {
          padding: 0,
        },
    },
    icon: {
        margin: 'auto'
    },
    wrapper: {
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(1),
        marginBlock: theme.spacing(4),
        textAlign: 'center'
    },
    title: {
        fontSize: theme.typography.h6.fontSize, 
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary
    },
    description: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.secondary
    },
    buttonContainer: {
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
}));

export default useStyles;
