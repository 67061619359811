import { reduxApi } from '../../../../services/reduxApi';
import { EmployeesBankBodyData } from './types';

const pendingEmployeesTags = {
  tag: 'PENDING_EMPLOYEES',
  listAbstractTag: { type: 'PENDING_EMPLOYEES', id: 'LIST' },
};

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [pendingEmployeesTags.tag],
  })
  .injectEndpoints({
    endpoints: builder => ({
      getBankList: builder.query({
        query: () => ({
          url: '/pix/key/banks',
        }),
      }),

      searchPendingEmployees: builder.query({
        query: ({ params = {}, filter = {}, companyCode = '' }) => ({
          url: `/employees/pending/${companyCode}`,
          method: 'POST',
          params,
          body: filter,
        }),
        providesTags: [pendingEmployeesTags.listAbstractTag],
      }),

      updateEmployeeBankData: builder.mutation({
        query: (body: EmployeesBankBodyData) => ({
          url: '/employees/bankData',
          method: 'PATCH',
          body,
        }),
        invalidatesTags: [pendingEmployeesTags.tag],
      }),
    }),
  });

export const {
  useSearchPendingEmployeesQuery,
  useUpdateEmployeeBankDataMutation,
  useGetBankListQuery,
} = slice;
