import React from 'react'
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import Buttons from '../../../../components/Buttons';
import currencyFormatter from '../../../../utils/currencyFormatter';
import {
    Typography,
    Box
  } from '@material-ui/core';

import useStyles from './styles';

const RequestLimitInfo = ({ amount }) => {
    const styles = useStyles();
    const history = useHistory();

    return (
        <Alert severity="info" className={styles.wrapper}>
            <Box>
                <Typography className={styles.title}>Limite pendente de aprovação</Typography>
                <Typography className={styles.description}>O novo limite solicitado de <b>{currencyFormatter(amount)}</b> precisa da aprovação dos autorizadores na tela de <b>Gerenciar solicitações</b> para entrar em vigor.</Typography>
            </Box>
            
            <Buttons.PrimaryButton
                variant='outlined'
                color='secondary'
                title="Gerenciar solicitações"
                onClick={()=>history.push('/cash-out/manage')}
            />
        </Alert>
    )
}

export default RequestLimitInfo
