import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { PayrollFileUploadActions as Actions } from '../../../../redux/store/Payroll/PayrollFiles';
import {
  useSearchProcessedFilesQuery,
  useSearchProcessedFilesAllCompaniesQuery,
} from '../../../../redux/store/Payroll/PayrollFiles/batchPayroll.api';
import {
  usePreAuthorizePayrollMutation,
  useValidateBalanceMutation,
  useVerifyIsPinRequiredQuery,
} from '../../../../redux/store/Payroll/payroll.api';
import { handleSnackbarStack } from '../../../../utils/handleSnackbarStack';

export function useMultipleFilesUploadReview() {
  const { error, warning } = handleSnackbarStack();
  const [batchList, setBatchList] = useState([]);
  const { code: companyCode } = useSelector(
    state => state.companies.currentCompany,
  );

  const { nsuList, nsuListToAuthorize, type } = useSelector(
    state => state.payroll.payrollFilesUpload,
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const requestId = useRef('');

  const hooks = {
    allCompanies: useSearchProcessedFilesAllCompaniesQuery({
      companyCodeId: companyCode,
    }),
    fileQuery: useSearchProcessedFilesQuery({ nsuList }),
  };

  const {
    data = [],
    isLoading: isSearchProcessedFilesLoading = false,
    isFetching: isSearchProcessedFilesFetching = false,
    isError: isSearchProcessedFilesError = false,
  } = hooks[type];

  useEffect(() => {
    !!data?.length && setBatchList(data);
  }, [data]);

  const [
    handleValidateBalance,
    { isLoading: isValidateBalanceLoading },
  ] = useValidateBalanceMutation();

  const { data: companyData } = useVerifyIsPinRequiredQuery({ companyCode });

  const [
    handlePreAuthorizePayroll,
    { isLoading: isPreAuthorizePayrollLoading },
  ] = usePreAuthorizePayrollMutation();

  const isAll = useCallback(
    currentBatch =>
      currentBatch?.paymentFiles?.every(file =>
        nsuListToAuthorize?.includes(file?.nsu),
      ),
    [nsuListToAuthorize],
  );

  const isIndeterminate = useCallback(
    currentBatch =>
      !isAll(currentBatch) &&
      currentBatch?.paymentFiles?.some(file =>
        nsuListToAuthorize?.includes(file?.nsu),
      ),
    [nsuListToAuthorize],
  );

  const companiesSelected = currentBatch => {
    return {
      nsuSelectedQtt: currentBatch?.paymentFiles?.filter(file =>
        nsuListToAuthorize?.includes(file?.nsu),
      )?.length,
      selectedAmount: currentBatch?.paymentFiles
        ?.filter(file => nsuListToAuthorize?.includes(file?.nsu))
        .map(file => file?.fileValidAmount)
        ?.reduce((prev, current) => prev + current, 0),
      isAll: isAll(currentBatch),
      isIndeterminate: isIndeterminate(currentBatch),
    };
  };

  const batchListParsed = useMemo(
    () =>
      batchList?.map(batch => ({
        ...batch,
        cardStatus: companiesSelected(batch),
      })),
    [batchList, nsuListToAuthorize],
  );

  const onBack = () => {
    dispatch(Actions.removeAllFiles());
    history.push('/payroll');
  };

  function removeNSUs(state, nsuListToRemove) {
    return state
      .map(company => {
        const filteredPayments = company.paymentFiles.filter(
          payment => !nsuListToRemove.includes(payment.nsu),
        );

        if (filteredPayments.length > 0) {
          return {
            ...company,
            paymentFiles: filteredPayments,
            fileCount: filteredPayments.length,
          };
        }

        return null;
      })
      .filter(company => company !== null);
  }

  const onPreAuthorizePayroll = useCallback(() => {
    handlePreAuthorizePayroll({
      emailNotReceived: false,
      nsuList: nsuListToAuthorize?.map(nsu => Number(nsu)),
    })
      .unwrap()
      .then(data => {
        requestId.current = data?.requestId;

        NiceModal.show('payroll-authorization-modal', {
          authData: { ...data, requestId: requestId.current },
          onSuccess: () => {
            const newState = removeNSUs(
              batchList,
              nsuListToAuthorize?.map(nsu => nsu),
            );
            setBatchList(newState);
          },
        });
      });
  }, [nsuListToAuthorize]);

  const onSubmit = useCallback(() => {
    handleValidateBalance({
      companyCode,
      nsuList: nsuListToAuthorize?.map(nsu => Number(nsu)),
    })
      .unwrap()
      .then(data => {
        if (companyData?.confirmPayroll) {
          onPreAuthorizePayroll();
        } else {
          NiceModal.show('payroll-authorization-modal', {
            authData: { requestId: requestId.current },
          });
        }
      });
  }, [companyData, nsuListToAuthorize]);

  const onSelectAll = companyName => {
    const currentBatch = batchList?.find(
      batch => batch?.companyName === companyName,
    );

    if (isAll(currentBatch)) {
      dispatch(Actions.removeAllNsuToAuthorize());

      return;
    }

    if (
      currentBatch?.companyBalance -
        currentBatch?.paymentFiles
          ?.map(file => file?.fileValidAmount)
          ?.reduce((prev, current) => prev + current, 0) <
      0
    ) {
      handleSnackbarStack().error(
        'Saldo disponível é insuficiente para selecionar todos os pagamentos!',
      );

      return;
    }

    if (isIndeterminate(currentBatch)) {
      dispatch(
        Actions.addNsuToAuthorize(
          currentBatch?.paymentFiles
            ?.map(file => file?.nsu)
            ?.filter(nsu => !nsuListToAuthorize?.includes(nsu)),
        ),
      );

      return;
    }

    dispatch(
      Actions.addNsuToAuthorize(
        currentBatch?.paymentFiles?.map(file => file?.nsu),
      ),
    );
  };

  return {
    batches: {
      onSelectAll,
      batchList: batchListParsed,
      isLoading:
        isSearchProcessedFilesLoading || isSearchProcessedFilesFetching,
      isError: isSearchProcessedFilesError,
      type,
    },
    bottomBar: {
      onBack,
      onSubmit,
      isLoading: isValidateBalanceLoading || isPreAuthorizePayrollLoading,
      hasSelectedAnyNsu: nsuListToAuthorize?.length > 0,
    },
  };
}
