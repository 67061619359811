import { alpha, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),

        '& > div': {
          padding: 0,
        },
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    title: {
        fontSize: '20px !important',
        fontWeight: 700,
        color: theme.palette.text.primary,
    },
    description: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 400,
        color: theme.palette.text.secondary,
    },
    labelKey: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 700,
        color: theme.palette.text.primary,
        marginTop: theme.spacing(1.5),
    },
    avatarContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflow: 'hidden',
        marginTop: theme.spacing(2),

        '& span, & p': {
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    },
    avatar: {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        borderRadius: theme.shape.borderRadius,
    },
    infoContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflow: 'hidden',
    },
    label: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(12),
    },
    content: {
        fontWeight: 500,
        color: theme.palette.text.primary,
        fontSize: theme.typography.pxToRem(14),
    },
    buttonContainer: {
        marginTop: theme.spacing(4),

        "& p": {
            fontWeight: 500,
        }
    }
}));

export default useStyles;
