export const PERIOD_TYPES = {
  DIURNAL: 'DIURNAL',
  NOTURNAL: 'NOTURNAL',
};

export const TRANSFER_TYPES = {
  PIX: 'PIX',
  BILL_PAYMENT: 'BILL_PAYMENT',
  INTERNAL_TRANSFER: 'INTERNAL_TRANSFER',
};

export const PERSON_TYPES = {
  PHYSICAL_PERSON: 'PHYSICAL_PERSON',
  LEGAL_PERSON: 'LEGAL_PERSON',
};
