export default function brazilianCurrencyFormatter(value: number): string {
  // Verifica se o valor é um número válido e se não é Infinity ou -Infinity
  if (isNaN(value) || value === Infinity || value === -Infinity) {
    return 'R$ 0,00';
  }

  // Formata o valor absoluto para o formato de moeda brasileiro
  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(Math.abs(value)); // Formata o valor absoluto

  // Se o valor for negativo, ajusta a formatação para incluir espaço
  return value < 0 ? amount.replace('R$', 'R$ -') : amount; // Adiciona espaço entre R$ e o sinal negativo
}
