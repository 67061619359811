import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Dialog,
  DialogActions,
  DialogContentText,
  Box,
  Avatar,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  BusinessRounded,
  MailOutlineRounded,
  SmartphoneRounded,
  VpnKeyOutlined,
} from '@material-ui/icons';

import Buttons from '../../../../../components/Buttons';
import useStyles from './styles';

import { useSelector } from 'react-redux';

import {CLAIM_TYPE, KEY_LABELS} from '../../../constants';

const ClaimPortability = NiceModal.create(
  ({ onSubmit, keyValue, keyType }) => {
    
  const { error } = useSelector(state => state.portabilityStatus);

  const isLoading = useSelector(state => state.portabilityStatus.isLoading);
  
  const modal = useModal();
  const styles = useStyles();

  const renderKeyTypeIcon = () => {
    if (keyType === 'PHONE')
      return <SmartphoneRounded color="primary" />;
    if (keyType === 'EMAIL')
      return <MailOutlineRounded color="primary" />;
    if (keyType === 'RANDOM') return <VpnKeyOutlined color="primary" />;
    if (keyType === 'CNPJ') return <BusinessRounded color="primary" />;

    return <VpnKeyOutlined color="primary" />;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={modal.visible}
      onClose={modal.remove}
      PaperProps={{ className: styles.root }}
    >
      
      <Box className={styles.wrapper}>
        <DialogContentText className={styles.title}>
          Essa chave foi registrada por outra pessoa. Deseja solicitar reivindicação?
        </DialogContentText>
        <DialogContentText className={styles.description}>
          Para trazer essa chave para a Somapay, você precisa solicitar a reivindicação e autorizar na instituição onde ela está cadastrada.
        </DialogContentText>
        {!!error && (
          <Alert severity="error">
            {error}
          </Alert>
        )}
        <Typography className={styles.labelKey}>
          Chave para reivindicação
        </Typography>
        <Box className={styles.avatarContainer}>
          <Avatar variant="rounded" className={styles.avatar}>
            {renderKeyTypeIcon()}
          </Avatar>

          <Box className={styles.infoContainer}>
            <Typography variant="label" className={styles.label}>
              {KEY_LABELS[keyType] || 'Chave'}
            </Typography>
            <Tooltip title={keyValue}>
              <Typography className={styles.content}>{keyValue}</Typography>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <DialogActions className={styles.buttonContainer}>
        <Buttons.PrimaryButton
          color="primary"
          variant=''
          onClick={modal.remove}
          title="CANCELAR"
          disabled={isLoading}
        />
        <Buttons.PrimaryButton
          color="secondary"
          onClick={()=>onSubmit(CLAIM_TYPE.OWNERSHIP)}
          title="SOLICITAR REIVINDICAÇÃO"
          loading={isLoading}
        />
      </DialogActions>
    </Dialog>
  );
});

export default ClaimPortability;
