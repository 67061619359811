import React, { useEffect, useState, createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import {
  useGetKeysListQuery,
  useDeletePixKeyMutation,
} from '../../../redux/store/Pix';

import { PixStatusActions } from '../../../redux/store/Pix/actions';

import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

const PixContext = createContext({});

export function PixProvider({ children }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { success, error } = handleSnackbarStack();

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [pixSelected, setPixSelected] = useState({});

  const companyCnpj = useSelector(state => state.companies.currentCompany.cnpj);
  const { hasError, message } = useSelector(state => {
    const { hasError, message } = state.pixStatus;
    return { hasError, message };
  });

  const { 
    data, 
    isFetching,
    isLoading,
    error: keysListError,
    refetch,
  } = useGetKeysListQuery({
    companyCnpj,
  });

  const [handleDeletePixKey] = useDeletePixKeyMutation();

  const handleCloseModal = () => {
    NiceModal.remove('delete-key-modal');
    dispatch(PixStatusActions.clearError());
  };

  const handleDeletion = async ({ key, type }) => {
    await handleDeletePixKey({ key, type, companyCnpj })
      .unwrap()
      .then(() => {
        handleCloseModal();
        setTimeout(() => success('Chave excluída com sucesso!'), 1000);
      });
  };

  const handleOpenDetailsPortability = pix => {
    const pixValue = pix || {};
    setPixSelected(pixValue);
    setDrawerIsOpen(!drawerIsOpen);
  };

  const onDeleteKey = ({ key, type }) => {
    NiceModal.show('delete-key-modal', {
      onClose: handleCloseModal,
      onDelete: () => handleDeletion({ key, type }),
    });
  };

  const goToNewKeyPage = () => {
    if (data?.length === 20) return;

    history.push('/pix/keys/new');

    sessionStorage.setItem(
      'useTopBar',
      JSON.stringify({
        name: 'Nova chave Pix',
        step: 0,
        route: '/pix/keys',
      }),
    );
  };

  useEffect(() => {
    if (hasError) error(message);
  }, [hasError, message]);

  const contentValue = {
    data,
    refetch,
    isLoading,
    pixSelected,
    isDisabled: data?.length === 20,
    isFetching,
    drawerIsOpen,
    onDeleteKey,
    goToNewKeyPage,
    handleOpenDetailsPortability,
    disableNewKeyPageButton: data?.length === 20,
  };

  return (
    <PixContext.Provider value={contentValue}>{children}</PixContext.Provider>
  );
}

export function usePix() {
  const context = useContext(PixContext);
  if (!context) {
    throw new Error('usePix must be used within a PixProvider');
  }
  return context;
}
