import { reduxApi } from '../../../services/reduxApi';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

const qrCodePixTags = {
    tag: 'QR_CODE_PIX',
};

interface Erros {
    errorCode: number;
    errorSource: string;
    errorReason: string;
    errorDetail: string;
    errorsDescription: string;
}

interface ResponseError {
    errors: Erros[];
}

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [qrCodePixTags.tag],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
        createRegenQrCodePix: builder.mutation<void, { cashinId: string, pixProvider: string }>({
            query: ({ cashinId, pixProvider }) => ({
                url: `/cashIn/pix/regen/${cashinId}`,
                method: 'POST',
                headers: {
                    'pixProvider': pixProvider,
                },
            }),

            transformErrorResponse: (response) => {
                const responseData = response.data as ResponseError;
                return responseData?.errors?.[0]?.errorDetail;
            },
        }),
    }),
  });

export const {
  useCreateRegenQrCodePixMutation,
} = slice;
