import React, { useState } from 'react';
import { Typography, Drawer, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import DrawerRequestTab from './RequestTab';
import DrawerReceiverTab from './ReceiverTab';

import { useStyles } from './styles';

export default function DetailsDrawer({ open, onClose, selected, status }) {
  const styles = useStyles();
  const [currentTab, changeTab] = useState('request');

  const isCashoutLimit = selected.requestType === 'CASHOUT_LIMIT'

  const handleChangeTab = (_, value) => {
    changeTab(value);
  };

  return (
    <Drawer
      ModalProps={{ disableScrollLock: true }}
      PaperProps={{ className: styles.root }}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <TabContext value={currentTab}>
        <Typography className={styles.title} variant="h3">
          Detalhes da solicitação
        </Typography>
        <TabList onChange={handleChangeTab} indicatorColor="primary">
          <Tab
            className={styles.tabLabel}
            value="request"
            label="Solicitação"
          />
          <Tab disabled={isCashoutLimit} className={styles.tabLabel} value="receiver" label="Recebedor" />
        </TabList>
        <TabPanel className={styles.tabPanel} value="request">
          <DrawerRequestTab status={status} selected={selected} />
        </TabPanel>
        <TabPanel className={styles.tabPanel} value="receiver">
          <DrawerReceiverTab status={status} selected={selected} />
        </TabPanel>
      </TabContext>
    </Drawer>
  );
}
