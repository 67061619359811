import React from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { Box, Typography } from '@material-ui/core';
import { EditOutlined, FiberManualRecord } from '@material-ui/icons';

import formatCPF from '../../../../../../../utils/formatCPF';
import { useGetBankListQuery } from '../../../../../../../redux/store/Employees/services/employees';

export function useTableConfig() {
  const { data: bankList } = useGetBankListQuery();

  const showEmployeeBankDataModal = employee => {
    NiceModal.show('handle-employee-bank-data', {
      employee: {
        ...employee,
        bank: employee?.bank || '',
        accountType: employee?.accountType ?? '',
        agency: employee?.agency ?? '',
        agencyDigit: employee?.agencyDigit ?? '',
        account: employee?.account ?? '',
        accountDigit: employee?.accountDigit ?? '',
      },
      bankList,
      employeeType: 'PENDING',
    });
  };

  return [
    {
      key: 'registration',
      type: 'field',
      label: 'Matrícula',
    },
    {
      key: 'cpf',
      type: 'field',
      label: 'CPF',
      render: row => (row?.cpf ? formatCPF(row?.cpf) : '-'),
    },
    {
      key: 'name',
      type: 'field',
      label: 'Nome',
      render: row => row?.name ?? '-',
    },
    {
      key: 'hasBankData',
      type: 'field',
      label: 'Dados bancários',
      render: ({ hasBankData }) => (
        <Box
          sx={{
            color: hasBankData ? '#19797F' : '#E54217',
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            gap: '0.2rem',
          }}
        >
          <FiberManualRecord style={{ fontSize: '0.6rem' }} />
          <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>
            {hasBankData ? 'Sim' : 'Não'}
          </Typography>
        </Box>
      ),
    },
    {
      key: 'employeeDetails',
      type: 'action',
      icon: <EditOutlined color="primary" />,
      label: 'Visualizar dados bancários',
      onClick: row => showEmployeeBankDataModal(row),
    },
  ];
}
