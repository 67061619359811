import React from 'react';
import useStyles from './styles';
import { ReactComponent as IconInfo } from '../../../../assets/iconInfo.svg';
import { ReactComponent as IconClock } from '../../../../assets/iconClock.svg';
import { ReactComponent as IconCheck } from '../../../../assets/iconCheck.svg';
import { Box, Typography, Avatar, Tooltip } from '@material-ui/core';
import Buttons from '../../../../components/Buttons';
import formatDate from '../../../../utils/formatDate';

import {
  BusinessRounded,
  MailOutlineRounded,
  SmartphoneRounded,
  VpnKeyOutlined,
} from '@material-ui/icons';

import { usePix } from '../../hooks/usePix';
import { usePortability } from '../../hooks/usePortability';

import { KEY_LABELS, CLAIM_LABEL, BUTTOM_MAP } from '../../constants';

const DrawerContent = () => {
    const styles = useStyles();
    const { pixSelected } = usePix();
    const { formatKeyValue, renderInfoText, historyList, getClaimType, onValidate } = usePortability()

    const renderKeyTypeIcon = () => {
        const iconMap = {
        PHONE: <SmartphoneRounded color="primary" />,
        EMAIL: <MailOutlineRounded color="primary" />,
        RANDOM: <VpnKeyOutlined color="primary" />,
        CNPJ: <BusinessRounded color="primary" />,
        };
        return iconMap[pixSelected?.type] || <VpnKeyOutlined color="primary" />;
    };

    const renderHistoryStep = (icon,title, content, date) => (
        <Box className={styles.historyStepCard}>
        <Box className={styles.historyIconContainer}>
            <div className="icon-container check">
                {icon}
            </div>
            <div className="divider" />
        </Box>
        <Box className={styles.historyTextContainer}>
            <Typography className="title" variant="h4">{title}</Typography>
            <Typography variant="subtitle2" className="content">{content}</Typography>
            <Typography variant="caption" className="date">{date}</Typography>
        </Box>
        </Box>
    );

    const renderHistoricItems = (historyItem) => {
        const { createdAt, status } = historyItem || {};
        const { claim, key } = pixSelected || {};
        const { claimType, claimPersonType, claimerBankName, resolutionPeriodEnd, completionPeriodEnd } = claim || {};

        const iconMap = {
            OPEN: <IconCheck />,
            WAITING_RESOLUTION: <IconClock />,
        };

        const titleMap = {
            PORTABILITY_CLAIMER_OPEN: 'Pedido de portabilidade solicitado',
            PORTABILITY_CLAIMER_WAITING_RESOLUTION: 'Pedido de portabilidade solicitado',
            PORTABILITY_DONOR_OPEN: 'Pedido de portabilidade recebido',
            PORTABILITY_DONOR_WAITING_RESOLUTION: 'Aguardando sua confirmação',
            
            OWNERSHIP_CLAIMER_OPEN: `Pedido de reivindicação de posse enviado`,
            OWNERSHIP_CLAIMER_WAITING_RESOLUTION: `Aguardando sua confirmação`,
            OWNERSHIP_DONOR_OPEN: `Pedido de reivindicação de posse`,
            OWNERSHIP_DONOR_WAITING_RESOLUTION: `Aguardando sua confirmação`,
        };
        
        const contentMap = {
            PORTABILITY_CLAIMER_OPEN: `Enviamos seu pedido de portabilidade para ${claimerBankName}.`,
            PORTABILITY_CLAIMER_WAITING_RESOLUTION: `Enviamos seu pedido de portabilidade para ${claimerBankName}.`,
            PORTABILITY_DONOR_OPEN: `Recebemos seu pedido de portabilidade para ${claimerBankName}.`,
            PORTABILITY_DONOR_WAITING_RESOLUTION: `Autorize ou negue o pedido de portabilidade para ${claimerBankName} até ${formatDate(resolutionPeriodEnd, 'DD/MM/YYYY')}. Caso contrário, o pedido será cancelado.`,
            
            OWNERSHIP_CLAIMER_OPEN: `Notificação enviada. Processo de reivindicação de posse iniciado. Aguardando resposta do usuário vinculado a chave. O prazo para resposta do usuário é de no máximo 14 dias a contar da data de notificação.`,
            OWNERSHIP_CLAIMER_WAITING_RESOLUTION: `Autorize ou negue o pedido de reivindicação de posse para outro usuário até ${formatDate(completionPeriodEnd, 'DD/MM/YYYY')}.`,
            OWNERSHIP_DONOR_OPEN: `Notificação do pedido de reivindicação de posse da chave ${key}.`,
            OWNERSHIP_DONOR_WAITING_RESOLUTION: `Autorize ou negue o pedido de reivindicação de posse para outro usuário até ${formatDate(completionPeriodEnd, 'DD/MM/YYYY')}.`,
        };

        return renderHistoryStep(
            iconMap[status],
            titleMap[`${claimType}_${claimPersonType}_${status}`],
            contentMap[`${claimType}_${claimPersonType}_${status}`],
            `${formatDate(createdAt, 'DD MMM YYYY [às] HH:mm')}`
        );
    };

    const renderButtons = () => {
        const claimTypeKey = getClaimType();
        const buttonConfig = BUTTOM_MAP.get(claimTypeKey);

        if (buttonConfig) {
            return (
                <Box className={styles.footer}>
                    {buttonConfig.buttons.map((button) => {
                    const variant = button.type === 'PrimaryButton' ? {} : {variant: ''}
                    return (
                        <Buttons.PrimaryButton
                        key={button.action}
                        color="secondary"
                        onClick={() => onValidate(button.action)}
                        title={button.title}
                        loading={false}
                        {...variant}
                        />
                    );
                    })}
                </Box>  
            );
        }
        
        return null;
    };
    
    return (
        <>
            <Box className={styles.wrapper}>
                <Typography variant="h5" className={styles.statusKeyTitle}>
                    {`Chave em processo de ${CLAIM_LABEL[pixSelected?.claim?.claimType]}`}
                </Typography>

                <Box className={styles.avatarContainer}>
                    <Avatar variant="rounded" className={styles.avatar}>
                    {renderKeyTypeIcon()}
                    </Avatar>
                    <Box className={styles.infoContainer}>
                    <Typography variant="label" className={styles.label}>
                        {KEY_LABELS[pixSelected?.type] || 'Chave'}
                    </Typography>
                    <Tooltip title={pixSelected?.key}>
                        <Typography className={styles.content}>
                        {formatKeyValue(pixSelected?.type, pixSelected?.key)}
                        </Typography>
                    </Tooltip>
                    </Box>
                </Box>

                <Box className={styles.notificationCard}>
                    <IconInfo />
                    <Box className={styles.notificationTextContainer}>
                        <Typography className={styles.notificationText}>{renderInfoText}</Typography>
                    </Box>
                </Box>

                <Typography variant="h2" className={styles.historyTitle}>Histórico</Typography>
                <Box className={styles.historyStepContainer}>
                    {historyList?.map(renderHistoricItems)}
                </Box>
                
            </Box>
            {renderButtons()}
        </>
    );
};

export default DrawerContent;
