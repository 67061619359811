import React from 'react';
import moment from 'moment';
import { Box, Chip } from '@material-ui/core';
import { VisibilityOutlined, ReplayOutlined } from '@material-ui/icons';

import Buttons from '../../../../../../../components/Buttons';

import currencyFormatter from '../../../../../../../utils/currencyFormatter';

import { STATUS_MAP } from '../../../../constants';
import useStyles from '../../Pending/styles';

import { renderRequestType } from '../../../../utils';

export default function useTableConfig({
  handleDetails,
  onOpenReprocessModal,
}) {
  const styles = useStyles();

  const renderValue = row => {
    return (
      <Chip
        style={{ backgroundColor: 'rgba(26, 26, 26, 0.1)', color: '#4F4F4F' }}
        label={currencyFormatter(row.amount)}
        size="small"
        variant="default"
        status="default"
      />
    );
  };

  const renderStatus = row => {
    const { label, color, background } = STATUS_MAP[row.status];

    return (
      <Chip
        style={{ backgroundColor: background, color }}
        size="small"
        variant="default"
        status="default"
        label={label}
      />
    );
  };

  const renderActions = row => {
    return (
      <Box className={styles.iconsContainer}>
        <Buttons.IconButton
          className={styles.iconButton}
          tooltipTitle="Visualizar detalhes"
          icon={<VisibilityOutlined color="primary" fontSize="small" />}
          onClick={() => handleDetails(row)}
        />
        {row.status === 'INSUFFICIENT_BALANCE_ERROR' && (
          <Buttons.IconButton
            className={styles.iconButton}
            tooltipTitle="Reenviar solicitação"
            icon={<ReplayOutlined color="primary" fontSize="small" />}
            onClick={() => onOpenReprocessModal(row)}
          />
        )}
      </Box>
    );
  };

  return [
    {
      key: 'creationUser',
      type: 'field',
      align: 'left',
      label: 'Solicitante',
    },
    {
      key: 'requestType',
      type: 'field',
      align: 'left',
      label: 'Tipo de solicitação',
      render: row => renderRequestType(row),
    },
    {
      key: 'creationDate',
      type: 'field',
      align: 'left',
      label: 'Data da solicitação',
      render: row => moment(row.creationDate).format('DD/MM/YYYY'),
    },
    {
      key: 'releaseDate',
      type: 'field',
      align: 'left',
      label: 'Data de envio',
      render: row =>
        row?.releaseDate ? moment(row?.releaseDate).format('DD/MM/YYYY') : '-',
    },
    {
      key: 'amount',
      type: 'field',
      align: 'left',
      label: 'Valor',
      render: row => renderValue(row),
    },
    {
      key: 'status',
      type: 'field',
      align: 'left',
      label: 'Situação',
      render: row => renderStatus(row),
    },
    {
      key: 'actions',
      type: 'field',
      align: 'center',
      customLabel: () => (
        <Box sx={{ width: '100%' }} className={styles.iconsContainer}>
          Ações
        </Box>
      ),
      render: row => renderActions(row),
    },
  ];
}
