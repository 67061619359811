import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.grey[300]}`,

      '& .MuiAccordionSummary-root': {
        padding: theme.spacing(0, 3),
      },

      '& .MuiAccordionDetails-root': {
        padding: `initial`,
        flexFlow: 'column nowrap',
      },

      '&.MuiAccordion-root.Mui-expanded ': {
        margin: 0,
      },
    },
    formTitle: {
      fontSize: '.875rem',
      color: theme.palette.text.primary,
      fontWeight: 700,
    },
  }),
);
