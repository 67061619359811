import React, { useState } from 'react';
import { Box, Collapse } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandLessRounded, ExpandMoreRounded } from '@material-ui/icons';
import CardTools from '../../../../../components/CardTools';
import Form from '../../../../../components/Form';
import { Charts } from '../../../../../components/Charts';
import { PayrollFileUploadActions } from '../../../../../redux/store/Payroll/PayrollFiles';
import currencyFormatter from '../../../../../utils/currencyFormatter';
import Buttons from '../../../../../components/Buttons';

export function PayrollOptions({ totalBalance = 0, options = {} }) {
  const { nsuListToAuthorize } = useSelector(
    state => state.payroll.payrollFilesUpload,
  );
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);

  const handleAddNsu = (validPaymentsCount, nsu) => {
    if (totalBalance - validPaymentsCount >= 0) {
      dispatch(PayrollFileUploadActions.addNsuToAuthorize(nsu));
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      }}
    >
      <Box sx={{ marginBottom: '1rem' }}>
        <Buttons.SecondaryButton
          color="primary"
          variant="text"
          endIcon={
            expanded ? (
              <ExpandLessRounded color="primary" />
            ) : (
              <ExpandMoreRounded color="primary" />
            )
          }
          title="Ver detalhamento da folha"
          onClick={() => setExpanded(prev => !prev)}
        />
      </Box>

      <Collapse
        style={{ width: '100%' }}
        in={expanded}
        mountOnEnter
        unmountOnExit
      >
        {options?.map(file => (
          <CardTools.GeneralCard key={file?.nsu} isBordered>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1.5rem',
                overflow: 'auto',
              }}
            >
              <Form.Checkbox
                disabled={
                  totalBalance - file?.fileValidAmount < 0 ||
                  file?.fileTotalAmount === 0
                }
                checked={nsuListToAuthorize?.includes(file?.nsu)}
                onChange={() => handleAddNsu(file?.fileValidAmount, file?.nsu)}
              />

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '3.5rem',
                }}
              >
                <CardTools.CardInfo title="NSU" description={file?.nsu} />

                <Charts.LinearWithMiddleSpace
                  height="0.25rem"
                  title="Registros:"
                  firstValueLabel="Válidos"
                  secondValueLabel="Inválidos"
                  firstColor="success"
                  secondColor="error"
                  totalValue={
                    parseInt(file?.validPaymentsCount) +
                    parseInt(file?.invalidPaymentsCount)
                  }
                  firstValue={parseInt(file?.validPaymentsCount)}
                  secondValue={parseInt(file?.invalidPaymentsCount)}
                />

                <CardTools.CardInfo
                  title="Valor total"
                  description={currencyFormatter(file?.fileTotalAmount)}
                />

                <CardTools.CardInfo
                  title="Válidos"
                  description={currencyFormatter(file?.fileValidAmount)}
                />
              </Box>
            </Box>
          </CardTools.GeneralCard>
        ))}
      </Collapse>
    </Box>
  );
}
