import React from 'react';
import { ModalDef } from '@ebay/nice-modal-react';

import ConfirmCommonPixKeyModal from './ConfirmCommonKeyModal';
import ConfirmUniquePixKeyModal from './ConfirmUniqueKeyModal';
import DeletePixKeyModal from './DeletePixKeyModal';
import ClaimPortability from './ClaimPortability';
import ClaimPortabilitySuccess from './ClaimPortabilitySuccess';
import ClaimPortabilityCancel from './ClaimPortabilityCancel';
import RequestPortability from './RequestPortability';
import RequestPortabilitySuccess from './RequestPortabilitySuccess';
import RequestPortabilityCancel from './RequestPortabilityCancel';

export default function PixKeysModals() {
  return (
    <>
      <ModalDef
        id="confirm-common-key-modal"
        component={ConfirmCommonPixKeyModal}
      />
      <ModalDef
        id="confirm-unique-key-modal"
        component={ConfirmUniquePixKeyModal}
      />
      <ModalDef id="delete-key-modal" component={DeletePixKeyModal} />
      <ModalDef id="claim-portability-modal" component={ClaimPortability} />
      <ModalDef id="claim-potability-success-modal" component={ClaimPortabilitySuccess} />
      <ModalDef id="claim-portability-cancel-modal" component={ClaimPortabilityCancel} />
      <ModalDef id="request-potability-modal" component={RequestPortability} />
      <ModalDef id="request-potability-success-modal" component={RequestPortabilitySuccess} />
      <ModalDef id="request-potability-cancel-modal" component={RequestPortabilityCancel} />
    </>
  );
}