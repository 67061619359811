import React, { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';

import {
  useGetPortabilityHistoryQuery,
  useValidatePortabilityPixKeyMutation,
  useCancelPortabilityMutation,
  useConfirmPortabilityMutation,
} from '../../../redux/store/Portability';

import formatDate from '../../../utils/formatDate';
import formatContactNumber from '../../../utils/formatContactNumber';
import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';
import formatCnpj from '../../../utils/formatCnpj';

import { usePix } from './usePix';

const PortabilityContext = createContext({});

export function PortabilityProvider({ children }) {
  const companyCnpj = useSelector(state => state.companies.currentCompany.cnpj);

  const { pixSelected, refetch, handleOpenDetailsPortability } = usePix();
  const { claim, key, type, taxIdNumber } = pixSelected || {};
  const {
    claimId,
    claimType,
    claimPersonType,
    claimerBankName,
    resolutionPeriodEnd,
    completionPeriodEnd,
  } = claim || {};

  const { data: historyList } = useGetPortabilityHistoryQuery(
    {
      claimId: pixSelected?.claim?.claimId,
      claimPersonType: pixSelected?.claim?.claimPersonType,
    },
    { skip: !pixSelected?.claim },
  );

  const { success } = handleSnackbarStack();

  const [validatePortabilityPixKey] = useValidatePortabilityPixKeyMutation();
  const [cancelPortability] = useCancelPortabilityMutation();
  const [confirmPortability] = useConfirmPortabilityMutation();

  const formatKeyValue = (keyType, keyValue) => {
    const formatMap = {
      // PHONE: formatContactNumber,
      CNPJ: formatCnpj,
    };
    return formatMap[keyType] ? formatMap[keyType](keyValue) : keyValue;
  };

  const renderInfoText = useMemo(() => {
    const messageMap = {
      PORTABILITY_DONOR: `Recebemos seu pedido de portabilidade desta chave. Se você realmente quiser mudar, você pode autorizar a portabilidade para ${claimerBankName} até ${formatDate(
        resolutionPeriodEnd,
        'DD/MM/YYYY',
      )}. Mas torcemos para que continue conosco!`,
      PORTABILITY_OTHER: `Para trazer a chave ${formatKeyValue(
        pixSelected?.type,
        pixSelected?.key,
      )} para a Somapay, confirme até ${formatDate(
        resolutionPeriodEnd,
        'DD/MM/YYYY',
      )}.`,
      OWNERSHIP_DONOR: `Outra pessoa quer registrar essa chave em uma conta. Se deseja manter sua chave na Somapay, faça uma nova validação de posse até ${formatDate(
        completionPeriodEnd,
        'DD/MM/YYYY',
      )}. Caso contrário, você pode liberar essa chave pra quem solicitou.`,
      OWNERSHIP_OTHER: `Para trazer a chave ${formatKeyValue(
        pixSelected?.type,
        pixSelected?.key,
      )} para a Somapay, você precisa esperar até ${formatDate(
        completionPeriodEnd,
        'DD/MM/YYYY',
      )} para a reivindicação ser confirmada.`,
    };

    const messageKey = `${claimType}_${
      claimPersonType === 'DONOR' ? 'DONOR' : 'OTHER'
    }`;

    return messageMap[messageKey];
  }, [pixSelected]);

  const onValidate = async actionType => {
    const validatePayload = {
      key,
      type,
      cnpj: companyCnpj,
      pixKeyValidationFlux: 'CLAIM',
      startTime: new Date(),
    };

    const typeCancelModal = {
      cancelClaim: 'claim-portability-cancel-modal',
      cancelPortability: 'request-potability-cancel-modal',
    };

    if (actionType === 'cancelClaim' || actionType === 'cancelPortability') {
      NiceModal.show(typeCancelModal[actionType], {
        keyValue: key,
        keyType: type,
        onSubmit: () => handleAction(actionType, '', ''),
        onResendPin: () => onValidate(),
      });
      return;
    }

    await validatePortabilityPixKey(validatePayload)
      .unwrap()
      .then(({ messageId }) => {
        NiceModal.show('confirm-unique-key-modal', {
          keyValue: key,
          keyType: type,
          onSubmit: pin => handleAction(actionType, pin, messageId),
          onResendPin: () => onValidate(),
        });
      });
  };

  const handleCloseModal = () => {
    NiceModal.remove('confirm-unique-key-modal');
    NiceModal.remove('claim-portability-cancel-modal');
    NiceModal.remove('request-potability-cancel-modal');
  };

  const handleActionSuccess = message => {
    refetch();
    handleOpenDetailsPortability();
    handleCloseModal();
    setTimeout(() => success(message), 1000);
  };

  const handleAction = (actionType, pin, messageId) => {
    switch (actionType) {
      case 'cancelClaim':
        cancelPortability({
          confirmationCode: null,
          messageId: '',
          key,
          keyType: type,
          taxId: taxIdNumber,
          cancelledBy: claimPersonType,
          claimId,
          startTime: new Date(),
        }).then(() => {
          handleActionSuccess('Reivindicação cancelada com sucesso!');
        });
        break;
      case 'releaseKey':
        confirmPortability({
          ownerTaxIdNumber: companyCnpj,
          claimId,
          startTime: new Date(),
        }).then(() => {
          handleActionSuccess('Chave Pix liberada com sucesso!');
        });

        break;
      case 'keepKey':
        cancelPortability({
          confirmationCode: pin.pin,
          messageId,
          key,
          keyType: type,
          taxId: taxIdNumber,
          cancelledBy: claimPersonType,
          claimId,
          startTime: new Date(),
        }).then(() => {
          handleActionSuccess('Chave Pix validada. Sua chave está ativa!');
        });

        break;
      case 'authorizePortability':
        confirmPortability({
          ownerTaxIdNumber: companyCnpj,
          claimId,
          startTime: new Date(),
        }).then(() => {
          handleActionSuccess('Portabilidade autorizada com sucesso!');
        });

        break;
      case 'cancelPortability':
        cancelPortability({
          confirmationCode: null,
          messageId: '',
          key,
          keyType: type,
          taxId: taxIdNumber,
          cancelledBy: claimPersonType,
          claimId,
          startTime: new Date(),
        }).then(() => {
          handleActionSuccess('Portabilidade cancelada com sucesso!');
        });

        break;
      default:
        break;
    }
  };

  const getClaimType = () => {
    if (claimType === 'PORTABILITY' && claimPersonType === 'DONOR') {
      return 'PORTABILITY_DONOR';
    }
    if (claimType === 'PORTABILITY' && claimPersonType === 'CLAIMER') {
      return 'PORTABILITY_CLAIMER';
    }
    if (claimType === 'OWNERSHIP' && claimPersonType === 'DONOR') {
      return 'OWNERSHIP_DONOR';
    }
    if (claimType === 'OWNERSHIP' && claimPersonType === 'CLAIMER') {
      return 'OWNERSHIP_CLAIMER';
    }
    return null;
  };

  const contentValue = {
    historyList,
    renderInfoText,
    onValidate,
    handleAction,
    formatKeyValue,
    getClaimType,
  };

  return (
    <PortabilityContext.Provider value={contentValue}>
      {children}
    </PortabilityContext.Provider>
  );
}

export function usePortability() {
  const context = useContext(PortabilityContext);
  if (!context) {
    throw new Error('usePortability must be used within a PortabilityProvider');
  }
  return context;
}
