import { reduxApi } from '../../../services/reduxApi';
import { LimitManagementActions } from '.';
import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

const limitManagementTags = {
  tag: 'LIMITS',
  formAbstractTag: { type: 'LIMITS', id: 'FORM' },
};

const slice = reduxApi
  .enhanceEndpoints({ addTagTypes: limitManagementTags.tag })
  .injectEndpoints({
    endpoints: builder => ({
      getLimitData: builder.query({
        query: ({ companyCnpj }) => `/limits/${companyCnpj}`,

        transformErrorResponse: response => {
          return response?.data?.errors?.[0]?.errorDetail;
        },

        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled.then(({ data }) => {
              dispatch(LimitManagementActions.setLimits(data));
            });
          } catch ({ error }) {
            handleSnackbarStack().error(error.message || 'Erro desconhecido');
          }
        },

        providesTags: [limitManagementTags.formAbstractTag],
      }),

      setNewLimit: builder.mutation({
        query: ({ companyCnpj, period, transferType, personType, limit }) => ({
          url: `/limits/${companyCnpj}`,
          method: 'PUT',
          body: {
            period,
            transferType,
            personType,
            newCashoutLimit: limit,
          },
        }),

        transformErrorResponse: response => {
          return response?.data?.errors?.[0]?.errorDetail;
        },

        onQueryStarted: async (_, { queryFulfilled }) => {
          try {
            await queryFulfilled;
          } catch ({ error }) {
            handleSnackbarStack().error(error.message);
          }
        },

        invalidatesTags: (_, error) =>
          error ? [] : [limitManagementTags.formAbstractTag],
      }),
      createNewRequestLimit: builder.mutation({
        query: ({ companyCode, amount, limitPeriod, limitTransferType, limitPersonType = null }) => ({
            url: `/request/${companyCode}/create`,
            method: 'POST',
            body: {
                amount,
                limitPeriod,
                limitTransferType,
                limitPersonType,
                requestType: 'CASHOUT_LIMIT',
            },
        }),

        onQueryStarted: async (payload, {dispatch, queryFulfilled }) => {
          const { limitTransferType, limitPersonType, limitPeriod, amount } = payload;

          try {
            await queryFulfilled.then(() => {

              dispatch(LimitManagementActions.updateNewLimitRequested({
                type: limitTransferType, 
                personType: limitPersonType, 
                period: limitPeriod, 
                newLimit: amount
              }));
            });
          } catch ({ error }) {
          }
        },

      })
    }),
  });

export const { useGetLimitDataQuery, useSetNewLimitMutation, useCreateNewRequestLimitMutation } = slice;
