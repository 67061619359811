import { alpha } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    accordion: {
      padding: theme.spacing(2, 2.5),
      marginBottom: theme.spacing(2),
      boxShadow: `0px 3.85px 7.7px 0px ${alpha(theme.palette.grey[600], 0.1)}`,

      '& .MuiAccordionSummary-content': {
        margin: 0,

        '&.Mui-expanded': {
          margin: 0,
        },
      },

      '& .MuiAccordionDetails-root': {
        padding: theme.spacing(2, 0),
      },
    },

    summary: {
      padding: 0,
    },

    avatar: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      marginRight: theme.spacing(2),
    },

    title: {
      fontSize: '1rem',
      fontWeight: 700,
      color: theme.palette.text.primary,
      display: 'flex',
      alignItems: 'center',
    },

    details: {
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: theme.spacing(2),
    },
  }),
);
