import React, { useState } from 'react';
import { IMaskMixin } from 'react-imask';
import { TextField } from '@material-ui/core';

const IMaskComponent = IMaskMixin(({ ...props }) => (
  <TextField
    size="small"
    variant="outlined"
    fullWidth
    InputProps={{ style: { borderRadius: '0.5rem' } }}
    itemScope
    {...props}
  />
));

export function MaskedField({
  name = '',
  label = '',
  disabled = false,
  value = '',
  onChange = () => {},
  mask = null,
  ...otherProps
}) {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);

  return (
    <IMaskComponent
      name={name}
      label={label}
      disabled={disabled}
      mask={mask}
      value={value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      InputLabelProps={{
        shrink: focused || value.length > 0,
      }}
      onAccept={newValue => {
        !!mask && onChange(newValue);
      }}
      onChange={event => {
        !mask && onChange(event.target.value);
      }}
      {...otherProps}
    />
  );
}
