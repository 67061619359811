export const PIX_TYPE_OPTIONS = [
  {
    label: 'CPF',
    value: 'CPF',
  },
  {
    label: 'Telefone',
    value: 'PHONE',
  },
  {
    label: 'Email',
    value: 'EMAIL',
  },
  {
    label: 'Chave aleatória',
    value: 'EVP',
  },
];

export const ACCOUNT_TYPE_ENUM = {
  'Conta Corrente': 'CC',
  'Conta Poupança': 'CP',
  'Conta Pagamento': 'CP',
};

export const ACCOUNT_TYPE_OPTIONS = [
  {
    label: 'Conta Corrente',
    value: 'CC',
  },
  {
    label: 'Conta Poupança',
    value: 'CP',
  },
  // {
  //   label: 'Conta Pagamento',
  //   value: 'CP',
  // },
];

export const PAYMENT_METHODS_OPTIONS = [
  {
    label: 'Pix via chave',
    value: 'pix',
  },
  {
    label: 'Pix via dados bancários',
    value: 'pixViaBankDetails',
  },
];
