import React, { memo, ReactNode } from 'react';

interface AccordionItemWrapperProps {
  children: ReactNode;
}

export const AccordionItemWrapper = memo(
  ({ children }: AccordionItemWrapperProps) => <>{children}</>,
  (prev, next) => prev.children !== next.children
);
