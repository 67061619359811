import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ActionsManualPayroll } from '../../../../../../../../../../redux/store/Payroll/ManualPayroll';

import {
  useGetPixInfoDictMutation,
  useGetBankListQuery,
  PixInfoDictResponse,
} from '../../../../../../../../../../redux/store/Employees/services/employees';

import { getCurrentCompanieData } from '../../../../../../../../../../utils/getCurrentCompanieData';
import { handleSnackbarStack } from '../../../../../../../../../../utils/handleSnackbarStack';

import { insertBankDataYupSchema } from '../Helpers/yupSchema';
import {
  PAYMENT_METHODS_OPTIONS,
  PIX_TYPE_OPTIONS,
  getPixMasks,
  ACCOUNT_TYPE_ENUM,
  validationSchema,
} from '../utils';

export function useInsertBankData() {
  const dispatch = useDispatch();

  const {
    currentCompany: { code },
    companies,
  } = useSelector(state => state.companies);

  const currentCompanyData = getCurrentCompanieData(code, companies);

  const [
    getPixInfoDictMutation,
    { isLoading: isLoadingPixInfo },
  ] = useGetPixInfoDictMutation();

  const { data: bankList, isLoading: isLoadingBankList } = useGetBankListQuery(
    {},
  );

  const {
    isOpenInsertBankDataDrawer,
    selectedEmployeeToInsertBankData,
    employeeBankData,
  } = useSelector(state => state.payroll.manualPayroll);

  const [infoDict, setInfoDict] = useState();
  const [shouldConfirmBankData, setShouldConfirmBankData] = useState(false);

  function resetForm() {
    reset({
      paymentMethod: '',
      pixType: '',
      pixKey: '',
      bank: '',
      accountType: '',
      agency: '',
      agencyDigit: '',
      account: '',
      accountDigit: '',
    });
  }

  function handleOpenInsertBankDataDrawer() {
    dispatch(ActionsManualPayroll.setIsOpenInsertBankDataDrawer(true));
  }

  function handleCloseInsertBankDataDrawer() {
    dispatch(ActionsManualPayroll.setSelectedEmployeeToInsertBankData({}));
    dispatch(ActionsManualPayroll.setIsOpenInsertBankDataDrawer(false));
    resetForm();
  }

  const isFilled = obj => {
    return !!obj && Object.keys(obj).length > 0;
  };

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid, dirtyFields },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const findCodeBank = (participant, prop = 'codIspb') => {
    if (!participant || !prop || !bankList) return '';

    const result = bankList.find(bank => bank[prop] === participant);
    return result?.codIspb || '';
  };

  const updateBankInfo = data => {
    const accountDigit = data.account.accountNumber.slice(-1);
    const account = data.account.accountNumber.slice(0, -1);
    const codeBank = findCodeBank(data.account.participant);

    setValue('account', account);
    setValue('bank', codeBank);
    setValue('accountDigit', accountDigit);
    setValue('agency', data.account.branch);
    setValue('accountType', ACCOUNT_TYPE_ENUM[data.account.accountType]);
  };

  async function getBankInfo() {
    const formValues = getValues();
    const pixKey =
      formValues.pixType === 'phone'
        ? `+55${formValues.pixKey}`
        : `${formValues.pixKey}`;

    if (isFilled(infoDict)) return onSubmit();

    try {
      const pixInfoDict = await getPixInfoDictMutation({
        key: pixKey,
        payerId: currentCompanyData.cnpj,
      }).unwrap();
      updateBankInfo(pixInfoDict);
      setInfoDict(pixInfoDict);
    } catch (_) {
      handleSnackbarStack().error(
        'Houve um erro ao buscar os dados bancários, tente mais tarde!',
      );
    }
  }

  function onSubmit() {
    const formValues = getValues();
    const isBankData = formValues.paymentMethod === 'pixViaBankDetails';

    const payload = {
      id: selectedEmployeeToInsertBankData.id,
      employeeType: selectedEmployeeToInsertBankData.employeeType,

      accountType: formValues.accountType,
      agency: formValues.agency,
      agencyDigit: formValues.agencyDigit,
      account: formValues.account,
      accountDigit: formValues.accountDigit,
      bankIspb: formValues.bank,
      useBankData: isBankData,
      ...(!isBankData && {
        endToEndId: infoDict?.endtoendid,
        participant: infoDict?.account.participant,
        pixKeyType: formValues.pixType,
        pixKey: formValues.pixKey,
      }),
    };

    dispatch(ActionsManualPayroll.updateEmployeeBankData(payload));
  }

  useEffect(() => {
    if (employeeBankData.successOn.hasSuccess === true) {
      dispatch(ActionsManualPayroll.searchEmployees());
      dispatch(ActionsManualPayroll.searchEmployeesPending());
      dispatch(ActionsManualPayroll.searchEmployeesReproved());
      dispatch(ActionsManualPayroll.setIsOpenInsertBankDataDrawer(false));
    }
  }, [employeeBankData.successOn.hasSuccess]);

  const setDefaultPaymentMethod = () => {
    if (
      !!selectedEmployeeToInsertBankData.hasBankData &&
      !!selectedEmployeeToInsertBankData?.pixKeyData?.key
    ) {
      return 'pix';
    }

    if (
      !!selectedEmployeeToInsertBankData.hasBankData &&
      !selectedEmployeeToInsertBankData?.pixKeyData?.key
    ) {
      return 'pixViaBankDetails';
    }
    return '';
  };

  useEffect(() => {
    const employee = selectedEmployeeToInsertBankData;
    console.log('employee', employee);

    const codeOrIspbProp = employee?.bankIspb ? 'codIspb' : 'bankCode';
    const codeOrIspbValue = employee?.bankIspb
      ? employee?.bankIspb
      : employee?.bank;

    const currentIspBank = findCodeBank(codeOrIspbValue, codeOrIspbProp);

    reset({
      paymentMethod: setDefaultPaymentMethod(),
      pixType: employee?.pixKeyData?.type || '',
      pixKey: employee?.pixKeyData?.key || '',
      bank: currentIspBank,
      accountType: employee.accountType,
      agency: employee.agency,
      agencyDigit: employee.agencyDigit,
      account: employee.account,
      accountDigit: employee.accountDigit,
    });

    return () => {
      setInfoDict({});
    };
  }, [selectedEmployeeToInsertBankData]);

  return {
    bankOptions: {
      data: bankList,
      isLoading: isLoadingBankList,
    },
    paymentMethodOptions: PAYMENT_METHODS_OPTIONS,
    pixOptions: PIX_TYPE_OPTIONS,
    getPixMasks,
    watchedValues: {
      paymentMethod: watch('paymentMethod'),
      pixType: watch('pixType'),
      pixKey: watch('pixKey'),
      bank: watch('bank'),
    },
    insertBankDataDrawer: {
      isOpen: isOpenInsertBankDataDrawer,
      handleOpen: handleOpenInsertBankDataDrawer,
      handleClose: handleCloseInsertBankDataDrawer,
    },
    insertBankDataForm: {
      setValue,
      onSubmit,
      handleSubmit: handleSubmit(onSubmit),
      getBankInfo: getBankInfo,
      reset,
      control,
      errors,
      shouldConfirmBankData: isFilled(infoDict),
      isLoading: isLoadingPixInfo,
      canSubmit: isValid && isFilled(dirtyFields),
    },
    employee: selectedEmployeeToInsertBankData,
    employeeName: selectedEmployeeToInsertBankData.name,
    employeeCpf: selectedEmployeeToInsertBankData.cpf,
    employeeBankData,
    infoDict,
  };
}
