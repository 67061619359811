import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: '2rem',
    },
    title: {
      padding: 0,
      fontWeight: 700,
      fontSize: '1.25rem',
      marginBottom: '0.75rem',
      color: '#1A1A1A',
    },
    descriptionType: {
      color: '#52197F',
    },
    infoEmphasis: {
      fontWeight:"bold",
      color: '#003768',
    },
    content: {
      padding: 0,
    },
    contentText: {
      color: '#4D4D4D',
      marginBottom: '0'
    },
    boxInput:{
        marginBottom:"16px"
    },
    actions: {
      padding: 0,
      marginTop: 'auto'
    },
    infoContainer: {
      marginBlock: '2rem',
    },
    input: {
      marginBottom: '1rem',
    },
  }),
);

export default useStyles;
