import { reduxApi } from '../../../services/reduxApi';
import { PortabilityStatusActions as Actions } from './actions';

const portabilityTags = {
    tag: 'PORTABILITY',
};

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [portabilityTags.tag],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      createNewPortabilityPixKey: builder.mutation({
        query: ({ key, keyType, claimType, companyCode, depositAccountId }) => ({
          url: `pix/claim/${companyCode}`,
          method: 'POST',
          headers: {
            'depositAccountId': depositAccountId,
          },
          body: {
            key,
            keyType,
            type: claimType,
          },
        }),

        transformErrorResponse: (response) => {
          return response?.data?.errors?.[0]?.errorDetail;
        },

        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
            try {
                dispatch(Actions.clearError());
                dispatch(Actions.setLoading(true));
                await queryFulfilled;
                dispatch(Actions.setLoading(false));
            } catch ({ error }) {
                dispatch(Actions.setLoading(false));
                dispatch(Actions.setError(error || 'Erro desconhecido'));
            }
        }
      }),

      // Validação via token
      validatePortabilityPixKey: builder.mutation({
        query: ({ type, key, cnpj, pixKeyValidationFlux, startTime }) => ({
          url: 'pix/claim/validate',
          method: 'POST',
          body: {
            type,
            key,
            cnpj,
            pixKeyValidationFlux,
            startTime,
          },
        }),
        transformErrorResponse: (response) => {
          return response?.data?.errors?.[0]?.errorDetail;
        },

        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
            try {
                dispatch(Actions.clearError());
                dispatch(Actions.setLoading(true));
                await queryFulfilled;
                dispatch(Actions.setLoading(false));
            } catch ({ error }) {
                dispatch(Actions.setLoading(false));
                dispatch(Actions.setError(error || 'Erro desconhecido'));
            }
        }
      }),

      // Cancelar portabilidade
      cancelPortability: builder.mutation({
        query: ({ confirmationCode, messageId, key, keyType, taxId, cancelledBy, claimId, startTime }) => ({
          url: 'pix/claim/cancel',
          method: 'DELETE',
          body: {
            confirmationCode,
            messageId,
            key,
            keyType,
            taxId,
            cancelledBy,
            claimId,
            startTime,
          },
        }),
        transformErrorResponse: (response) => {
          return response?.data?.errors?.[0]?.errorDetail;
        },

        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
            try {
                dispatch(Actions.clearError());
                dispatch(Actions.setLoading(true));
                await queryFulfilled;
                dispatch(Actions.setLoading(false));
            } catch ({ error }) {
                dispatch(Actions.setLoading(false));
                dispatch(Actions.setError(error || 'Erro desconhecido'));
            }
        }
      }),

      // Confirmar portabilidade
      confirmPortability: builder.mutation({
        query: ({ ownerTaxIdNumber, claimId, startTime }) => ({
          url: 'pix/claim/confirm',
          method: 'POST',
          body: {
            ownerTaxIdNumber,
            claimId,
            startTime,
          },
        }),
        transformErrorResponse: (response) => {
          return response?.data?.errors?.[0]?.errorDetail;
        },

        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
            try {
                dispatch(Actions.clearError());
                dispatch(Actions.setLoading(true));
                await queryFulfilled;
                dispatch(Actions.setLoading(false));
            } catch ({ error }) {
                dispatch(Actions.setLoading(false));
                dispatch(Actions.setError(error || 'Erro desconhecido'));
            }
        }
      }),

      // Histórico de portabilidade
      getPortabilityHistory: builder.query({
        query: ({ claimId, claimPersonType }) => ({
          url: `pix/claim/history/${claimId}`,
          method: 'GET',
          params: {
            claimPersonType,
          },
        }),
      }),
      transformErrorResponse: (response) => {
        return response?.data?.errors?.[0]?.errorDetail;
      },

      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          try {
              dispatch(Actions.clearError());
              dispatch(Actions.setLoading(true));
              await queryFulfilled;
              dispatch(Actions.setLoading(false));
          } catch ({ error }) {
              dispatch(Actions.setLoading(false));
              dispatch(Actions.setError(error || 'Erro desconhecido'));
          }
      }
    }),
  });

export const {
  useCreateNewPortabilityPixKeyMutation,
  useValidatePortabilityPixKeyMutation,
  useCancelPortabilityMutation,
  useConfirmPortabilityMutation,
  useGetPortabilityHistoryQuery,
} = slice;
