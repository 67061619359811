import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSelector } from 'react-redux';
import { useEffect, useMemo, useCallback } from 'react';
import currencyFormatter from '../../../../../utils/currencyFormatter';
import { handleSnackbarStack } from '../../../../../utils/handleSnackbarStack';

import { useSetNewLimitMutation } from '../../../../../redux/store/LimitManagement/limits.api';

import { TRANSFER_TYPES } from '../../../constants';
import { validationsLimitForm } from '../validations';

export default function useLimitForm({
  currentPeriod,
  currentPerson,
  currentType,
}) {
  const { success, error } = handleSnackbarStack();

  const [
    handleSetLimit,
    { isLoading: isSubmitting },
  ] = useSetNewLimitMutation();

  const limits = useSelector(state => state.limitManagement.limits);
  const companyCnpj = useSelector(state => state.companies.currentCompany.cnpj);

  const currentEntry = useMemo(() => {
    if (!limits || !limits?.length) return null;

    if (currentType === TRANSFER_TYPES.PIX) {
      return limits.find(
        ({ type, personType, period }) =>
          type === currentType &&
          personType === currentPerson &&
          period === currentPeriod,
      );
    }

    return limits.find(
      ({ type, period }) => type === currentType && period === currentPeriod,
    );
  }, [limits]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      limit: 0,
    },
    resolver: yupResolver(validationsLimitForm(currentEntry?.maxLimitAllowed)),
  });

  const onSubmit = ({ limit }) => {
    handleSetLimit({
      companyCnpj,
      limit,
      period: currentPeriod,
      personType: currentPerson,
      transferType: currentType,
    })
      .unwrap()
      .then(() => {
        success('Limite alterado com sucesso!');
      })
      .catch(err => {
        error(err.message || 'Erro desconhecido');
      });
  };

  const onReset = useCallback(() => {
    if (currentEntry && currentEntry?.currentLimit) {
      setValue('limit', currentEntry?.currentLimit);
      return;
    }

    setValue('limit', 0);
  }, [currentEntry]);

  const marks = [
    {
      value: 0,
      label: currencyFormatter(0),
    },
    {
      value: currentEntry?.maxLimitAllowed,
      label: currencyFormatter(currentEntry?.maxLimitAllowed),
    },
  ];

  useEffect(() => {
    onReset();
  }, [onReset]);

  return {
    control,
    errors,
    isValid,
    hasPreviouslyRequested: currentEntry?.newLimitRequested,
    isDirty,
    isLoading: isSubmitting,
    currentEntryValue: currentEntry?.newLimitRequested,
    marks,
    maxLimit: currentEntry?.maxLimitAllowed,
    onReset,
    onSubmit: () => handleSubmit(onSubmit),
  };
}
