import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),

        '& > div': {
          padding: 0,
        },
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    title: {
        fontSize: '20px !important',
        fontWeight: 700,
        color: theme.palette.text.primary,
    },
    description: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 400,
        color: theme.palette.text.secondary,
    },
    buttonContainer: {
        marginTop: theme.spacing(2.5),

        "& p": {
            fontWeight: 500,
        }
    },
}));

export default useStyles;
