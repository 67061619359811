import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NiceModal from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import { handleSnackbarStack } from '../../../../../utils/handleSnackbarStack';
import { useCreateNewRequestLimitMutation } from '../../../../../redux/store/LimitManagement/limits.api';
import { validationsIncreaseValueForm } from '../validations'
export default function useIncreaseValueForm({
  maxLimit,
  period, 
  type,
  person
}) {
  const maxLimitModifield = maxLimit
  const { success } = handleSnackbarStack();
    
  const [
    createNewRequestLimit,
    { isLoading, error}
  ] = useCreateNewRequestLimitMutation();

  const companyCode = useSelector(state => state.companies.currentCompany.code);

  const { 
    control,
    formState,
    handleSubmit
  } = useForm({
      mode: 'onChange',
      defaultValues: { requestValue: 0 },
      resolver: yupResolver(validationsIncreaseValueForm(maxLimitModifield)),
  });

  const onSubmit = ({ requestValue }) => {
    createNewRequestLimit({
      companyCode,
      amount: requestValue,
      limitPeriod: period,
      limitTransferType: type,
      limitPersonType: !!person ? person : null
    })
      .unwrap()
      .then(() => {
        success('Aumento de limite solicitado com sucesso!');
        NiceModal.remove('request-increase-value')
      })
  };

  return {
    control,
    formState,
    isLoading,
    errorRequest: error,
    onSubmit: () => handleSubmit(onSubmit),
  };
}
