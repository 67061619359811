import React, { useMemo, useState } from 'react';
import { Typography, Box, Divider, Tooltip, Collapse } from '@material-ui/core';
import { DomainRounded, InfoOutlined } from '@material-ui/icons';

import Form from '../../../../../components/Form';

import CardTools from '../../../../../components/CardTools';
import { InfoCard } from '../../../../../components/InfoCard';
import { Charts } from '../../../../../components/Charts';
import { PayrollOptions } from '../PayrollOptions';
import currencyFormatter from '../../../../../utils/currencyFormatter';
import { useStyles } from './styles';

export function PayrollPreviewCard({ payroll = {}, onSelect = () => {} }) {
  const classes = useStyles();

  const finalAmount =
    parseFloat(payroll?.companyBalance) -
    parseFloat(payroll?.cardStatus?.selectedAmount);

  const totalValidAmount = useMemo(
    () =>
      payroll?.paymentFiles
        ?.map(file => file?.fileValidAmount)
        ?.reduce((prev, current) => prev + current, 0),
    [payroll],
  );

  const totalInvalidAmount = useMemo(
    () =>
      payroll?.paymentFiles
        ?.map(file => file?.fileInvalidAmount)
        ?.reduce((prev, current) => prev + current, 0),
    [payroll],
  );

  return (
    <CardTools.GeneralCard
      style={{ marginBottom: '64px' }}
      className={`${payroll?.cardStatus?.isAll ? classes.cardWrapper : ''}`}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Box
          style={{ overflow: 'auto', gap: 2 }}
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
              gap: '2rem',
            }}
          >
            <Form.Checkbox
              indeterminate={payroll?.cardStatus?.isIndeterminate}
              checked={payroll?.cardStatus?.isAll}
              onChange={onSelect}
              disabled={totalValidAmount + totalInvalidAmount === 0}
            />

            <InfoCard
              icon={<DomainRounded />}
              title="Empresa"
              data={payroll?.companyName || '-'}
            />

            <CardTools.CardInfo
              title="Quantidade de folhas"
              description={payroll?.fileCount || '-'}
            />

            <CardTools.CardInfo
              title="Saldo da conta"
              description={currencyFormatter(payroll?.companyBalance) || '-'}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Divider
              flexItem
              orientation="vertical"
              style={{ marginLeft: 16 }}
            />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '0.5rem',
              }}
            >
              <CardTools.CardInfo
                title={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Saldo disponível da conta
                    <Tooltip
                      arrow
                      placement="top-end"
                      title="Saldo atual da conta da empresa, descontando os valores das folhas de pagamento já selecionadas na tela."
                    >
                      <InfoOutlined color="gray" style={{ width: '1.25rem' }} />
                    </Tooltip>
                  </Box>
                }
                titleStyle={{
                  alignSelf: 'end',
                }}
                description={currencyFormatter(finalAmount)}
                descriptionStyle={{
                  fontSize: '1rem',
                  fontWeight: 700,
                  alignSelf: 'end',
                }}
              />

              <Typography variant="caption" style={{ alignSelf: 'end' }}>
                {payroll?.cardStatus?.nsuSelectedQtt} de{' '}
                {payroll?.paymentFiles?.length} folhas selecionadas
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box sx={{ width: '100%', overflow: 'auto' }}>
          <Charts.LinearWithMiddleSpace
            firstColor="success"
            secondColor="error"
            totalValue={currencyFormatter(
              totalValidAmount + totalInvalidAmount,
            )}
            firstValue={currencyFormatter(totalValidAmount)}
            secondValue={currencyFormatter(totalInvalidAmount)}
            title="Valor total"
            firstValueLabel="Valor válido"
            secondValueLabel="Valor inválido"
          />
        </Box>

        <PayrollOptions
          totalBalance={finalAmount}
          options={payroll?.paymentFiles}
        />
      </Box>
    </CardTools.GeneralCard>
  );
}
