import React, { createContext, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';

import { AccordionContext } from './context';

import { useStyles } from './styles';

export default function FormAccordion({ title = '', children }) {
  const styles = useStyles();

  const formattedTitle = title.replace(/\s/g, '');

  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      className={styles.root}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreRounded />}
        aria-controls={`${formattedTitle}-content`}
      >
        <Typography component="h3" className={styles.formTitle}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails aria-controls={`${formattedTitle}-content`}>
        <AccordionContext.Provider value={{ setExpanded }}>
          {children}
        </AccordionContext.Provider>
      </AccordionDetails>
    </Accordion>
  );
}
