import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 'fit-content',
    height: 'fit-content',
    marginLeft: 'auto',
  },
  kebabButton: {
    height: theme.spacing(3),
    padding: 0,
    minWidth: 'fit-content',
    minHeight: 'fit-content',
    '& svg': {
      width: theme.spacing(3),
      minWidth: theme.spacing(3),
    },
  },
  kebabMenu: {
    marginTop: theme.spacing(10),
    marginLeft: 'auto',
    transform: 'translateY(8px)',
  },
  kebabItensContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    paddingInline: theme.spacing(1),
  },
}));

export default useStyles;
