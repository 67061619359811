import * as yup from 'yup';
import currencyFormatter from '../../../../utils/currencyFormatter';

export const validationsIncreaseValueForm = (maxLimit) =>{
  return (
      yup.object().shape({
          requestValue: yup
              .number()
              .min(maxLimit, `O valor deve ser maior que o limite atual que é de ${currencyFormatter(maxLimit)}`)
              .required(),
      })
  )
} 

export const validationsLimitForm = maxLimit =>
  yup.object().shape({
    limit: yup
      .number()
      .min(0)
      .max(maxLimit),
  });
