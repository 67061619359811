import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContentText,
  Box
} from '@material-ui/core';

import Buttons from '../../../../../components/Buttons';
import useStyles from './styles';

const ClaimPortabilityCancel = NiceModal.create(
  ({ onSubmit }) => {

  const isLoading = useSelector(state => state.portabilityStatus.isLoading);

  const modal = useModal();
  const styles = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={modal.visible}
      onClose={modal.remove}
      PaperProps={{ className: styles.root }}
    >
      <Box className={styles.wrapper}>
        <DialogContentText className={styles.title}>
          Cancelar reivindicação?
        </DialogContentText>
        <DialogContentText className={styles.description}>
          Ao confirmar, cancelaremos o processo de reivindicação da chave.
        </DialogContentText>
      </Box>
      <DialogActions className={styles.buttonContainer}>
        <Buttons.PrimaryButton
          color="primary"
          variant=''
          onClick={modal.remove}
          title="NÃO"
          disabled={isLoading}
        />
        <Buttons.PrimaryButton
          color="secondary"
          onClick={()=>onSubmit()}
          title="SIM"
          loading={isLoading}
        />
      </DialogActions>
    </Dialog>
  );
});

export default ClaimPortabilityCancel;
