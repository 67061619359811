import { reduxApi } from '../../../../services/reduxApi';

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [],
  })
  .injectEndpoints({
    endpoints: builder => ({
      searchProcessedFiles: builder.query({
        query: ({ nsuList = [] }) => ({
          url: '/files/employeespayments/search-processed',
          method: 'POST',
          body: nsuList,
        }),
        transformResponse: response =>
          response?.map(value => ({
            ...value,
            key: crypto.randomUUID(),
          })),
      }),

      searchProcessedFilesAllCompanies: builder.query({
        query: ({ companyCodeId }) => ({
          url: 'files/employeespayments/search-pending-all-companies',
          method: 'GET',
          headers: {
            companyCodeId,
          },
        }),
        transformResponse: response =>
          response?.map(value => ({
            ...value,
            key: crypto.randomUUID(),
          })),
      }),
    }),
  });

export const {
  useSearchProcessedFilesQuery,
  useSearchProcessedFilesAllCompaniesQuery,
} = slice;
