import { makeStyles, createStyles, alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      backgroundColor: alpha(theme.palette.warning.light, 0.1),
      marginBlock: theme.spacing(4),
      '& .MuiAlert-message': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(2)
      },
      '& svg': {
        fill: theme.palette.warning.dark,
      },
      '& b': {
        fontWeight: 'bold',
      },
      '& button': {
        whiteSpace: 'nowrap',
        minWidth: 'fit-content',
        height: 'fit-content',
      },
    },
    title: {
      fontWeight: 'bold',
      color: theme.palette.text.primary, 
    },
    description: {
      fontSize: '12px',
      color: theme.palette.text.secondary,
    },
  }),
);

export default useStyles;
