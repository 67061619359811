import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  Grid,
  List,
  ListItem,
  Typography,
  Box,
  Tooltip,
  CircularProgress,
  IconButton,
} from '@material-ui/core';

import { ArrowRight } from '@material-ui/icons';


import { useDispatch, useSelector } from 'react-redux';
import FakeList from './UI/FakeList';
import colorStatus from './UI/ColorStatus';

import styles from './styles.module.css';
import truncateString from '../../utils/truncateString';

import { ReactComponent as ArrowPurpleMobile } from '../../assets/arrow-purple-mobile.svg';
import { ActionsPayrollDetails } from '../../redux/store/Payroll/PayrollDetails';
import { ReactComponent as QrcodeIcon } from '../../assets/qrcodeIcon.svg';
import { ReactComponent as ResetIcon } from '../../assets/resetIcon.svg';

function MobileList({
  data,
  loadingList,
  pageSize,
  setPageSize,
  getData,
  currentPageSize,
  totalSize,
  payments,
  setFetchData,
  anticipations,
  fetchData,
  isLoadingQrCode,
  cashInSelected,
  handleClickQrCode
}) {

  const dispatch = useDispatch();

  const { url } = useRouteMatch();

  const history = useHistory();

  const { accountSelected } = useSelector(state => state.account);

  useEffect(() => getData(), [pageSize, accountSelected]);

  function fetchMoreData() {
    setPageSize(pageSize + 10);
    setFetchData(true);
  }

  const handleListItemClicked = (id, element) => {
    sessionStorage.setItem(
      'useTopBar',
      JSON.stringify({
        name:
          element?.type === 'TED' ? 'Detalhes da TED' : 'Detalhes do boleto',
        step: 0,
        route: '/cash-in',
      }),
    );

    history.push(`/cash-in/teds/${id}`);
  };

  const onDetailsClicked = payrollData => {
    dispatch(ActionsPayrollDetails.setPayrollBasicData(payrollData));

    sessionStorage.setItem(
      'useTopBar',
      JSON.stringify({
        name: 'Detalhes do pagamento',
        step: 0,
        route: '/payroll',
      }),
    );

    sessionStorage.setItem('payrollData', JSON.stringify(payrollData));

    history.push(`/payroll/details`);
  };

  function currencyBRL(value) {
    let options = { style: 'currency', currency: 'BRL' };
    return value?.toLocaleString('pt-BR', options);
  }

  const getStatus = (isExpired, row) => {
    const propId = row?.id ? 'id' : 'cashEntryId'
    
    if (!!isLoadingQrCode && cashInSelected?.[propId] == row?.[propId]) return 'isLoading';
    if (isExpired) return 'expired';
    return 'valid';
  };

  const renderIconButton = (row) => {
    if(row?.status === 'Aguardando Autorização' || row?.type === 'PIX'){
      const isExpired = row?.qrcodeExpired
    
      const status = getStatus(isExpired, row)
  
      const iconMap = {
        'expired': <ResetIcon color='red' fill='red' path='red' colo/>,
        'valid': <QrcodeIcon />,
        'isLoading': <CircularProgress size={20}/>
      }

      return (
        <IconButton 
          onClick={() => handleClickQrCode(row)}
          aria-label="qr-code-pix"
          style={{padding: 0}}
        >
          {iconMap[status]}
        </IconButton>
      );
    }
  };

  return (
    <div className={styles.containerMobileList}>
      <List component="nav">
        {!fetchData && loadingList ? (
          <FakeList />
        ) : (
          <InfiniteScroll
            dataLength={data?.length}
            next={fetchMoreData}
            hasMore
            loader={
              fetchData && currentPageSize < totalSize - 5 ? (
                <div className={styles.circularProgressContainer}>
                  <CircularProgress
                    size={25}
                    className={styles.circularProgress}
                  />
                </div>
              ) : (
                ''
              )
            }
          >
            {data?.map(element => (
              <ListItem
                className={styles.listItemContainer}
              >
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Grid
                    item
                    container
                    direction="row"
                    wrap="nowrap"
                    justify="space-between"
                    className={styles.statusContainer}
                  >
                    <Grid item container alignItems="center">
                      <Grid item className={styles.icon}>
                        {colorStatus(element.status)}
                      </Grid>
                      <Grid item>
                        <Typography className={styles.statusLabel}>
                          {' '}
                          {element.status}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Box display="flex" flexDirection="row">
                        {handleClickQrCode && renderIconButton(element)}
                        <IconButton
                          aria-label="open-details"
                          onClick={()=>{
                            if(!payments && element.type === 'TED'){
                              return handleListItemClicked(element.id, element)
                            }
                            
                            onDetailsClicked(element, element.nsu)
                          }}
                        >
                          <ArrowPurpleMobile  width={30} height={30}/>
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>

                  {payments ? (
                    <Grid item className={styles.nsu}>
                      <Typography className={styles.nsuLabel}>
                        NSU {element.nsu}
                      </Typography>
                    </Grid>
                  ) : (
                    ''
                  )}

                  <Grid item className={styles.type}>
                    <Typography className={styles.typeLabel}>
                      {payments
                        ? truncateString(element.paymentType, 22)
                        : element.type}
                    </Typography>
                  </Grid>
                  
                  <Grid item className={styles.amount}>
                    <Typography className={styles.amountLabel}>
                      {payments
                        ? currencyBRL(element.totalAmount)
                        : currencyBRL(element.amount)}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </InfiniteScroll>
        )}
      </List>
    </div>
  );
}

export default MobileList;
