import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import React from 'react';

export function GeneralCard({
  isBordered = false,
  title,
  action,
  children,
  className = '',
  style,
}) {
  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        padding: '1rem',
        borderRadius: '0.5rem',
        border: isBordered ? '1px solid #E5E5E5' : 'none',
        boxShadow: isBordered ? 'none' : '0px 4px 10px #3b35411f',
        ...style,
      }}
      className={className}
    >
      {(title || action) && (
        <CardHeader
          title={
            <Typography
              style={{
                fontSize: '1.25rem',
                fontWeight: 'bold',
                lineHeight: '1.688rem',
                letterSpacing: '-0.04em',
              }}
            >
              {title}
            </Typography>
          }
          action={action}
          style={{ padding: 0 }}
        />
      )}
      <CardContent style={{ padding: 0 }}>{children}</CardContent>
    </Card>
  );
}
