import { useSelector } from 'react-redux';

import { useGetLimitDataQuery } from '../../../redux/store/LimitManagement/limits.api';

export default function useManageLimits() {
  const companyCnpj = useSelector(state => state.companies.currentCompany.cnpj);

  const { data, isFetching } = useGetLimitDataQuery({ companyCnpj });

  return { data, isFetching };
}
