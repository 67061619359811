import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    fieldset: {
      border: 'none',
      padding: theme.spacing(0, 3),

      '& .MuiSlider-markLabel': {
        transform: 'initial',

        '&[data-index="1"]': {
          right: 0,
          left: 'initial !important',
        },
      },
    },

    legendContainer: {
      display: 'flex',
      marginBottom: theme.spacing(2.5),
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: theme.spacing(1),
    },

    legend: {
      fontSize: '.875rem',

      [theme.breakpoints.down('sm')]: {
        fontSize: '.75rem',
      },
    },

    time: {
      color: theme.palette.text.secondary,
      fontSize: '.75rem',

      [theme.breakpoints.down('sm')]: {
        fontSize: '.625rem',
      },
    },

    field: {
      marginBottom: theme.spacing(4),
    },

    buttonsContainer: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 3),
      marginBottom: theme.spacing(5),

      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column nowrap',
        gap: theme.spacing(2),
      },
    },
    submitContainer: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-end',
      gap: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column nowrap',
        gap: theme.spacing(1),
      },
    },
  }),
);
