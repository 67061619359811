import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { Box, Slider, Typography } from '@material-ui/core';
import NiceModal from '@ebay/nice-modal-react';

import {
  AddRounded,
  WbSunnyOutlined,
  Brightness2Outlined,
} from '@material-ui/icons';

import Form from '../../../../components/Form';
import Buttons from '../../../../components/Buttons';
import RequestLimitInfo from '../RequestLimitInfo';

import { AccordionContext } from '../FormAccordion/context';
import useLimitForm from './hooks/useLimitForm';
import { PERIOD_TYPES } from '../../constants';

import { useStyles } from './styles';

export default function LimitForm({ person = '', period = '', type = '' }) {
  const styles = useStyles();
  const { setExpanded } = useContext(AccordionContext);

  const {
    control,
    errors,
    onSubmit,
    isValid,
    isDirty,
    maxLimit,
    marks,
    isLoading,
    currentEntryValue,
    hasPreviouslyRequested,
    onReset,
  } = useLimitForm({
    currentPeriod: period,
    currentPerson: person,
    currentType: type,
  });

  const renderIcon = () => {
    if (period === PERIOD_TYPES.DIURNAL) {
      return <WbSunnyOutlined />;
    }

    if (period === PERIOD_TYPES.NOTURNAL) {
      return <Brightness2Outlined />;
    }
  };

  const handleCancel = () => {
    setExpanded(false);
    onReset();
  };

  const renderTimeString =
    period === PERIOD_TYPES.NOTURNAL ? '(08h às 20h)' : '(20h às 08h)';

  const renderLabel =
    period === PERIOD_TYPES.NOTURNAL ? 'Limite noturno' : 'Limite diurno';


  const handleRequestIncreaseValue = ()=>{
    NiceModal.show('request-increase-value',{
      period,
      type,
      maxLimit,
      person,
    })
  }

  return (
    <Form.FormBase onSubmit={onSubmit}>
      <Box
        component="fieldset"
        className={styles.fieldset}
        aria-labelledby={`${period}-${type}-${person}`}
      >
        <Box className={styles.legendContainer}>
          {renderIcon()}
          <Typography
            id={`${period}-${type}-${person}`}
            component="legend"
            variant="caption"
            className={styles.legend}
          >
            {renderLabel}{' '}
            <Typography
              component="span"
              variant="caption"
              className={styles.time}
            >
              {renderTimeString}
            </Typography>
          </Typography>
        </Box>

        {hasPreviouslyRequested && <RequestLimitInfo amount={currentEntryValue}/>}

        <Controller
          control={control}
          name="limit"
          render={({ field }) => (
            <>
              <Form.CurrencyField
                label={renderLabel}
                maxValue={maxLimit}
                className={styles.field}
                InputLabelProps={{ shrink: true }}
                {...field}
                error={errors?.limit}
                helperText={errors?.limit?.message}
                disabled={hasPreviouslyRequested}
              />
              <Slider
                marks={marks}
                step={375}
                min={0}
                max={maxLimit}
                className={styles.field}
                value={field.value}
                disabled={hasPreviouslyRequested}
                onChange={(_, value) => field.onChange(value)}
              />
            </>
          )}
        />
      </Box>

      <Box className={styles.buttonsContainer}>
        <Buttons.SecondaryButton
          variant="text"
          color="primary"
          title="Solicitar aumento de limite"
          icon={<AddRounded color="primary" />}
          onClick={() => handleRequestIncreaseValue()}
          disabled={hasPreviouslyRequested}
        />

        {isDirty && (
          <Box className={styles.submitContainer}>
            <Buttons.SecondaryButton
              onClick={handleCancel}
              color="primary"
              variant="text"
              title="Cancelar"
            />
            <Buttons.PrimaryButton
              type="submit"
              color="secondary"
              title="Salvar alteração"
              disabled={hasPreviouslyRequested || !isValid}
              loading={isLoading}
            />
          </Box>
        )}
      </Box>
    </Form.FormBase>
  );
}
