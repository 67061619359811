import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Alert } from '@material-ui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import Buttons from '../../../../../components/Buttons';
import Form from '../../../../../components/Form';

import { ReactComponent as Locked } from '../../../../../assets/lockedIllustration.svg';

import validations from './validations';

import { useStyles } from './styles';

const INITIAL_TIMER = 80;

const ConfirmUniquePixKeyModal = NiceModal.create(
  ({ onSubmit, onResendPin, keyValue, keyType }) => {
    const modal = useModal();
    const styles = useStyles();

    const { hasError, message } = useSelector(state => {
      const { hasError, message } = state.pixStatus;
      return { hasError, message };
    });

    const isLoading = useSelector(state => state.pixStatus.isLoading);

    const padTime = time =>
      String(time).length === 1 ? `0${time}` : `${time}`;

    const format = time => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;

      return `0${minutes}:${padTime(seconds)}`;
    };

    const [counter, setCounter] = useState(INITIAL_TIMER);

    const {
      handleSubmit,
      control,
      reset,
      formState: { isValid },
    } = useForm({
      mode: 'onChange',
      defaultValues: { pin: '' },
      resolver: yupResolver(validations),
    });

    const handlePinSubmit = (form, event) => {
      event.preventDefault();
      onSubmit(form);
    };

    const handleResend = () => {
      onResendPin();
      setCounter(INITIAL_TIMER);
    };

    const renderKeyDescriptionText = () => {
      switch (keyType) {
        case 'PHONE':
          return (
            <>
              Enviamos um código de 6 dígitos para o número de celular{' '}
              <strong>{keyValue}</strong>.
            </>
          );
        case 'EMAIL':
          return (
            <>
              Enviamos um código de 6 dígitos para o e-mail{' '}
              <strong>{keyValue}</strong>.
            </>
          );
      }
    };

    useEffect(() => {
      reset();
    }, []);

    useEffect(() => {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

      return () => clearTimeout();
    }, [counter]);

    const handleError = ()=>{
      if(message === 'EXISTENTE_OUTRO_DONO'){
        return 'A chave Pix informada já está registrada sob outro proprietário.'
      }
      if(message === 'EXISTENTE_MESMO_DONO_OUTRO_PSP'){
        return 'A chave Pix já está registrada em outra instituição.'
      }
    }
  

    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={modal.visible}
        onClose={modal.remove}
        PaperProps={{ className: styles.root }}
      >
        <Form.FormBase onSubmit={() => handleSubmit(handlePinSubmit)}>
          <Locked className={styles.lockedIcon} />
          <DialogTitle className={styles.title}>
            Insira o código de confirmação
          </DialogTitle>
          <DialogContent className={styles.content}>
            <DialogContentText className={styles.contentText}>
              {renderKeyDescriptionText()}
            </DialogContentText>

            {hasError ? (
              <Alert severity="error" className={styles.alert}>
                {handleError()}
              </Alert>
            ) : null}

            <Controller
              control={control}
              name="pin"
              render={({ field }) => (
                <Form.TextField
                  className={styles.pinField}
                  size="medium"
                  label="Digite o código de confirmação"
                  {...field}
                  disabled={isLoading}
                />
              )}
            />

            <Buttons.PrimaryButton
              variant="text"
              title={`Reenviar código${
                counter ? ` em ${format(counter)}` : ''
              }`}
              disabled={counter > 0}
              className={styles.resendButton}
              onClick={handleResend}
            />
          </DialogContent>
          <DialogActions className={styles.actions}>
            <Buttons.PrimaryButton
              color="primary"
              variant="text"
              title="CANCELAR"
              onClick={modal.remove}
              disabled={isLoading}
            />
            <Buttons.PrimaryButton
              color="secondary"
              title="CONFIRMAR"
              type="submit"
              disabled={!isValid}
              loading={isLoading}
            />
          </DialogActions>
        </Form.FormBase>
      </Dialog>
    );
  },
);

export default ConfirmUniquePixKeyModal;
