export const KEY_LABELS = {
  PHONE: 'Celular',
  EMAIL: 'E-mail',
  CNPJ: 'CNPJ',
  EVP: 'Chave aleatória',
};

export const CLAIM_TYPE = {
  OWNERSHIP: 'OWNERSHIP',
  PORTABILITY: 'PORTABILITY',
}

export const CLAIM_LABEL = {
  OWNERSHIP: 'reivindicação',
  PORTABILITY: 'portabilidade',
}

export const KEY_STATUSES = {
  Ativo: {
    label: 'Ativo',
    status: 'success',
  },
  Pendente: {
    label: 'Pendente',
    status: 'warning',
  },
  PORTABILITY_WAITING_RESOLUTION: {
    label: 'Portabilidade solicitada',
    status: 'warning',
  },
  OWNERSHIP_WAITING_RESOLUTION: {
    label: 'Reivindicação solicitada',
    status: 'warning',
  },
  Portabilidade: {
    label: 'Em processo de portabilidade',
    status: 'error',
  },
  Erro: {
    label: 'Erro',
    status: 'error',
  },
  Expirado: {
    label: 'Expirado',
    status: 'error',
  },
};

export const MESSAGE_PORTABILITY_MAP = new Map([
  [
    'PORTABILITY_DONOR',
    {
      title: 'Pedido de portabilidade recebido',
      content: (claimerBankName) => `Recebemos seu pedido de portabilidade para ${claimerBankName}.`,
    },
  ],
  [
    'PORTABILITY_NON_DONOR',
    {
      title: 'Pedido de portabilidade solicitado',
      content: (claimerBankName) => `Enviamos seu pedido de portabilidade para ${claimerBankName}.`,
    },
  ],
  [
    'OWNERSHIP_DONOR',
    {
      title: 'Pedido de reivindicação de posse',
      content: (_, keyFormatted) => `Notificação do pedido de reivindicação de posse da chave ${keyFormatted} enviado.`,
    },
  ],
  [
    'OWNERSHIP_NON_DONOR',
    {
      title: 'Pedido de reivindicação de posse enviado',
      content: () => `Notificação enviada. Processo de reivindicação de posse iniciado. Aguardando resposta do usuário vinculado à chave. O prazo para resposta do usuário é de no máximo 14 dias a contar da data de notificação.`,
    },
  ],
]);

export const BUTTOM_MAP = new Map([
  [
    'PORTABILITY_DONOR',
    {
      buttons: [
        {
          type: 'SecondaryButton',
          title: 'Autorizar portabilidade',
          action: 'authorizePortability',
        },
        {
          type: 'PrimaryButton',
          title: 'Manter chave na Somapay',
          action: 'keepKey',
        },
      ],
    },
  ],
  [
    'PORTABILITY_CLAIMER',
    {
      buttons: [
        {
          type: 'PrimaryButton',
          title: 'Cancelar portabilidade',
          action: 'cancelPortability',
        },
      ],
    },
  ],
  [
    'OWNERSHIP_DONOR',
    {
      buttons: [
        {
          type: 'SecondaryButton',
          title: 'Liberar chave',
          action: 'releaseKey',
        },
        {
          type: 'PrimaryButton',
          title: 'Manter chave na Somapay',
          action: 'keepKey',
        },
      ],
    },
  ],
  [
    'OWNERSHIP_CLAIMER',
    {
      buttons: [
        {
          type: 'PrimaryButton',
          title: 'Cancelar reivindicação',
          action: 'cancelClaim',
        },
      ],
    },
  ],
]);