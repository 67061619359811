import NiceModal from '@ebay/nice-modal-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useLazyGetEmployeesListQuantityQuery } from '../../../../redux/store/Employees/services/employees';
import { handleSnackbarStack } from '../../../../utils/handleSnackbarStack';

export function useEmployeesList() {
  const companyCode = useSelector(state => state.companies.currentCompany.code);
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState('approved');
  const [totalApprovedEmployees, setTotalApprovedEmployees] = useState(0);
  const [totalPendingEmployees, setTotalPendingEmployees] = useState(0);
  const [totalReprovedEmployees, setTotalReprovedEmployees] = useState(0);

  const [
    handleGetEmployeesListQuantity,
    { isLoading },
  ] = useLazyGetEmployeesListQuantityQuery();

  const uploadDataEmployees = JSON.parse(
    localStorage.getItem(`uploadDataEmployees-${companyCode}`),
  );

  function handleEmployeesRegisterBatchOptionClick() {
    if (
      uploadDataEmployees &&
      uploadDataEmployees?.status === 'PROCESSING' &&
      uploadDataEmployees?.companyCode === companyCode
    ) {
      NiceModal.show('already-upload-register-file');
    } else {
      history.push('/employees/register-batch/menu');
    }
  }

  useEffect(() => {
    handleGetEmployeesListQuantity(companyCode)
      .then(({ data }) => {
        setTotalApprovedEmployees(data?.approvedEmployee);
        setTotalPendingEmployees(data?.pendingEmployee);
        setTotalReprovedEmployees(data?.reprovedEmployee);
      })
      .catch(() => {
        handleSnackbarStack().error(
          'Houve um erro ao buscar a quantidade de funcionários',
        );
      });

    return () => {
      setTotalApprovedEmployees(0);
      setTotalPendingEmployees(0);
      setTotalReprovedEmployees(0);
    };
  }, []);

  return {
    history,
    handleEmployeesRegisterBatchOptionClick,
    currentTab,
    handleChangeTab: (_, tab) => setCurrentTab(tab),
    totalApprovedEmployees: isLoading ? '-' : totalApprovedEmployees,
    totalPendingEmployees: isLoading ? '-' : totalPendingEmployees,
    totalReprovedEmployees: isLoading ? '-' : totalReprovedEmployees,
  };
}
