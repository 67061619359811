import React from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { Box, Typography } from '@material-ui/core';
import {
  EditOutlined,
  FiberManualRecord,
  InfoOutlined,
} from '@material-ui/icons';

import formatCPF from '../../../../../../../utils/formatCPF';
import { situationOfRejectedEmployees } from '../../../../../../../mocks/situationOfEmployees';
import { useGetBankListQuery } from '../../../../../../../redux/store/Employees/services/employees';
import Buttons from '../../../../../../../components/Buttons';

export function useTableConfig() {
  const { data: bankList } = useGetBankListQuery();

  function handleOpenInfoDrawer() {
    NiceModal.show('employee-situation-info-drawer');
  }

  function handleShowEmployeeBankDataModal(employee) {
    NiceModal.show('handle-employee-bank-data', {
      employee: {
        ...employee,
        bank: employee?.bank || '',
        accountType: employee?.accountType ?? '',
        agency: employee?.agency ?? '',
        agencyDigit: employee?.agencyDigit ?? '',
        account: employee?.account ?? '',
        accountDigit: employee?.accountDigit ?? '',
      },
      bankList,
      employeeType: 'REPROVED',
    });
  }

  return [
    {
      key: 'registration',
      type: 'field',
      label: 'Matrícula',
    },
    {
      key: 'cpf',
      type: 'field',
      label: 'CPF',
      render: row => (row?.cpf ? formatCPF(row?.cpf) : '-'),
    },
    {
      key: 'name',
      type: 'field',
      label: 'Nome',
      render: row => row?.name ?? '-',
    },
    {
      key: 'hasBankData',
      type: 'field',
      label: 'Dados bancários',
      render: ({ hasBankData }) => (
        <Box
          sx={{
            color: hasBankData ? '#19797F' : '#E54217',
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            gap: '0.2rem',
          }}
        >
          <FiberManualRecord style={{ fontSize: '0.6rem' }} />
          <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>
            {hasBankData ? 'Sim' : 'Não'}
          </Typography>
        </Box>
      ),
    },
    {
      key: 'situationType',
      type: 'field',
      label: 'Situação',
      customLabel: () => (
        <Buttons.IconButton
          icon={<InfoOutlined style={{ fontSize: '1.2rem' }} />}
          color="primary"
          onClick={handleOpenInfoDrawer}
          style={{ width: '1rem', height: '1rem' }}
        />
      ),
      render: ({ situationType }) =>
        situationOfRejectedEmployees.find(item => item.search === situationType)
          .text,
    },
    {
      key: 'employeeDetails',
      type: 'action',
      icon: <EditOutlined color="primary" />,
      label: 'Visualizar dados bancários',
      onClick: row => handleShowEmployeeBankDataModal(row),
    },
  ];
}
