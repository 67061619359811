import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import { useAutocompleteField } from './Hooks/useAutocompleteField';

export function AutocompleteField({
  fullWidth = true,
  name = '',
  label = '',
  size = 'small',
  variant = 'outlined',
  disabled = false,
  value = '',
  onChange = () => {},
  isLoading = false,
  options = [
    { label: 'Option 1', value: 1 },
    { label: 'Option 2', value: 2 },
  ],
  getOptionLabel = item => item?.label ?? '',
  getOptionValue = item => item?.value ?? '',
  noOptionsText = 'Nenhum resultado encontrado',
  hasClearButton = false,
  returnObject = false,
  ...otherProps
}) {
  const { findValueInArray } = useAutocompleteField();

  return (
    <Autocomplete
      fullWidth={fullWidth}
      size={size}
      disabled={disabled}
      value={findValueInArray(options, value, getOptionValue) ?? null}
      onChange={(e, i) => onChange(returnObject ? i : getOptionValue(i))}
      options={Array.isArray(options) ? options : []}
      getOptionLabel={getOptionLabel}
      loading={isLoading}
      noOptionsText={noOptionsText}
      clearOnEscape={hasClearButton}
      renderInput={params => (
        <TextField
          {...otherProps}
          {...params}
          name={name}
          label={label}
          variant={variant}
          InputProps={{
            fullWidth,
            style: { borderRadius: '0.5rem' },
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
